
export const createOrder = async (orderData) => {
    console.log(orderData);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData)
        });

        if (!response.ok) {
            throw new Error('Error al crear el pedido');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const getOrders = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}orders`);
        
        if (!response.ok) {
            throw new Error('Error al obtener los pedidos');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const updateOrderStatus = async (orderId, status) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}orders/${orderId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ estado: status })
        });

        if (!response.ok) {
            throw new Error('Error al actualizar el estado del pedido');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}; 

export const deleteOrder = async (orderId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}orders/${orderId}`, { method: 'DELETE' });
        return await response.json();
    } catch (error) {
        throw error;
    }
};
