import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Box,
    TextField,
    Button,
    IconButton,
    Typography,
    Grid,
    InputAdornment,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Chip,
    Rating as MuiRating,
    Switch
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CollectionsIcon from '@mui/icons-material/Collections';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useSettings } from '../../context/SettingsContext';
import { 
    deleteProduct,
    uploadProductImages,
    updateProduct,
    getAllProducts
} from '../../services/product';
import { getCategories } from '../../services/category';
import { getColors } from '../../services/color';
import { getSizes } from '../../services/sizes';
import ProductFormDialog from './ProductFormDialog';
import ProductImagesDialog from './ProductImagesDialog';
import { uploadImage } from '../../services/uploadImgBB';

function ProductsPanel() {
    const { settings } = useSettings();
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openImagesDialog, setOpenImagesDialog] = useState(false);
    const [selectedProductForImages, setSelectedProductForImages] = useState(null);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        try {
            const [productsData, categoriesData, colorsData, sizesData] = await Promise.all([
                getAllProducts(),
                getCategories(),
                getColors(),
                getSizes()
            ]);
            setProducts(productsData);
            setCategories(categoriesData);
            setColors(colorsData);
            setSizes(sizesData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este producto?')) {
            try {
                await deleteProduct(id);
                fetchInitialData();
            } catch (error) {
                console.error('Error al eliminar producto:', error);
            }
        }
    };

    const handleEdit = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedProduct(null);
        setOpenDialog(false);
        fetchInitialData();
    };

    const filteredProducts = useMemo(() => 
        products.filter(product =>
            product.nombre.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        [products, searchTerm]
    );

    const handleImageUpload = async (productId, event) => {
        event.preventDefault();
        const uploadPromises = Array.from(event.target.files).map(image => uploadImage(image));
        const uploadedUrls = await Promise.all(uploadPromises);

        try {
            await uploadProductImages(productId, uploadedUrls);
            fetchInitialData();
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handleViewImages = (product) => {
        setSelectedProductForImages(product);
        setOpenImagesDialog(true);
    };

    const handleToggleVisibility = async (product) => {
        try {
            await updateProduct(product.id, {
                ...product,
                visible: product.visible ? 0 : 1
            });
            fetchInitialData();
        } catch (error) {
            console.error('Error al cambiar visibilidad:', error);
        }
    };

    const handleStockChange = useCallback(async (product, checked, e) => {
        e.stopPropagation();
        try {
            await updateProduct(product.id, {
                ...product,
                in_stock: checked ? 1 : 0
            });
            fetchInitialData();
        } catch (error) {
            console.error('Error al actualizar el stock:', error);
        }
    }, []);

    const handleNewChange = useCallback(async (product, checked, e) => {
        e.stopPropagation();
        try {
            await updateProduct(product.id, {
                ...product,
                is_new: checked ? 1 : 0
            });
            fetchInitialData();
        } catch (error) {
            console.error('Error al actualizar estado de nuevo:', error);
        }
    }, []);

    const handleEnableSizesChange = useCallback(async (product, checked, e) => {
        e.stopPropagation();
        try {
            await updateProduct(product.id, {
                ...product,
                enable_sizes: checked,
                tallas: checked ? product.tallas : []
            });
            fetchInitialData();
        } catch (error) {
            console.error('Error al actualizar enable_sizes:', error);
        }
    }, []);

    const handleEnableColorsChange = useCallback(async (product, checked, e) => {
        e.stopPropagation();
        try {
            await updateProduct(product.id, {
                ...product,
                enable_colors: checked,
                colores: checked ? product.colores : []
            });
            fetchInitialData();
        } catch (error) {
            console.error('Error al actualizar enable_colors:', error);
        }
    }, []);

    // Función auxiliar para verificar si un objeto es válido y tiene ID
    const isValidItem = (item) => item && (item.id || item.id_talla || item.id_color);

    // Función para obtener el ID seguro
    const getSafeId = (item) => {
        if (!item) return 'unknown';
        return item.id || item.id_talla || item.id_color || 'unknown';
    };

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
            }}>
                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                    Gestión de Productos
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenDialog(true)}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        '&:hover': {
                            backgroundColor: '#c4ae9d'
                        }
                    }}
                >
                    Nuevo Producto
                </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar producto..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#5a4a42' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: '#f8f4f1',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#d7c0ae',
                            },
                            '&:hover fieldset': {
                                borderColor: '#c4ae9d',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#5a4a42',
                            },
                        },
                    }}
                />
            </Box>

            <Grid container spacing={3}>
                {filteredProducts.map(product => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                        <Card 
                            sx={{ 
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#ffffff',
                                borderRadius: 2,
                                boxShadow: '4px 4px 8px #e1ddd9, -4px -4px 8px #ffffff',
                                position: 'relative'
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={Array.isArray(product.imagenes) && product.imagenes.length > 0 
                                    ? product.imagenes[0].url 
                                    : 'ruta/a/imagen/por/defecto.jpg'}
                                alt={product.nombre}
                                sx={{ objectFit: 'cover' }}
                            />
                            
                            <CardContent sx={{ flexGrow: 1, p: 2 }}>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: '#5a4a42',
                                        mb: 1,
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        minHeight: '40px',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        lineHeight: '1.2em'
                                    }}
                                >
                                    {product.nombre}
                                </Typography>
                                
                                <Box sx={{ mb: 2 }}>
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: '#b0a199',
                                            minHeight: '60px',
                                            maxHeight: '80px',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: 'vertical',
                                            lineHeight: '1.2em'
                                        }}
                                    >
                                        {product.descripcion}
                                    </Typography>
                                </Box>

                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    mb: 1
                                }}>
                                    <MuiRating
                                        value={Number(product.rating) || 0}
                                        readOnly
                                        size="small"
                                        sx={{
                                            '& .MuiRating-iconFilled': {
                                                color: '#ffd700',
                                            }
                                        }}
                                    />
                                </Box>

                                <Box sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 2
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            color: '#e57373',
                                            fontSize: '1.1rem'
                                        }}
                                    >
                                        {settings.find(setting => setting.key_name === 'moneda')?.value}{product.precio_oferta || product.precio}
                                    </Typography>
                                    {product.precio_oferta && (
                                        <Typography sx={{ 
                                            color: '#b0a199',
                                            textDecoration: 'line-through',
                                            fontSize: '0.9rem'
                                        }}>
                                            {settings.find(setting => setting.key_name === 'moneda')?.value}{product.precio}
                                        </Typography>
                                    )}
                                </Box>

                                <Box sx={{ 
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                    mb: 2
                                }}>
                                    {Array.isArray(product.colores) && 
                                     product.colores
                                        .filter(isValidItem)
                                        .map(color => (
                                            <Box
                                                key={`color-${product.id}-${getSafeId(color)}`}
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: '50%',
                                                    backgroundColor: color.codigo,
                                                    border: '1px solid rgba(0,0,0,0.1)'
                                                }}
                                            />
                                        ))}
                                </Box>

                                <Box sx={{ 
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                }}>
                                    {Array.isArray(product.tallas) && 
                                     product.tallas
                                        .filter(isValidItem)
                                        .map(talla => {
                                            return (
                                                <Chip
                                                    key={`talla-${product.id}-${getSafeId(talla)}`}
                                                    label={talla.talla}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: 'rgba(215,192,174,0.2)',
                                                        color: '#5a4a42'
                                                    }}
                                                />
                                            );
                                        })}
                                </Box>
                            </CardContent>

                            <CardActions 
                                sx={{ 
                                    p: 2,
                                    borderTop: '1px solid rgba(0,0,0,0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        display: 'flex', 
                                        width: '100%',
                                        gap: 1  // Espacio uniforme entre todos los iconos
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEdit(product);
                                        }}
                                        sx={{ 
                                            color: '#5a4a42',
                                            backgroundColor: 'rgba(215,192,174,0.2)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(215,192,174,0.3)'
                                            }
                                        }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDelete(product.id)}
                                        sx={{ 
                                            color: '#e57373',
                                            backgroundColor: 'rgba(229,115,115,0.1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(229,115,115,0.2)'
                                            }
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleViewImages(product)}
                                        sx={{ 
                                            color: '#5a4a42',
                                            backgroundColor: 'rgba(215,192,174,0.2)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(215,192,174,0.3)'
                                            }
                                        }}
                                    >
                                        <CollectionsIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleToggleVisibility(product)}
                                        sx={{ 
                                            color: product.visible ? '#4caf50' : '#b0a199',
                                            '&:hover': {
                                                backgroundColor: 'rgba(215,192,174,0.2)'
                                            }
                                        }}
                                    >
                                        {product.visible ? (
                                            <VisibilityIcon fontSize="small" />
                                        ) : (
                                            <VisibilityOffIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id={`image-upload-${product.id}`}
                                        type="file"
                                        multiple
                                        onChange={(e) => handleImageUpload(product.id, e)}
                                    />
                                    <label htmlFor={`image-upload-${product.id}`}>
                                        <IconButton
                                            component="span"
                                            size="small"
                                            sx={{ 
                                                color: '#5a4a42',
                                                backgroundColor: 'rgba(215,192,174,0.2)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(215,192,174,0.3)'
                                                }
                                            }}
                                        >
                                            <AddPhotoAlternateIcon fontSize="small" />
                                        </IconButton>
                                    </label>
                                </Box>

                                <Box sx={{ 
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                    mt: 1,
                                    px: 1
                                }}>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            '& .MuiSwitch-root': { 
                                                scale: '0.7'
                                            }
                                        }}>
                                            <Typography variant="caption" sx={{ color: '#2196F3', fontSize: '0.7rem' }}>
                                                Stock
                                            </Typography>
                                            <Switch
                                                checked={product.in_stock === 1}
                                                onChange={(e) => handleStockChange(product, e.target.checked, e)}
                                                color="primary"
                                                size="small"
                                            />
                                        </Box>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            '& .MuiSwitch-root': { 
                                                scale: '0.7'
                                            }
                                        }}>
                                            <Typography variant="caption" sx={{ color: '#4CAF50', fontSize: '0.7rem' }}>
                                                Nuevo
                                            </Typography>
                                            <Switch
                                                checked={product.is_new === 1}
                                                onChange={(e) => handleNewChange(product, e.target.checked, e)}
                                                color="success"
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            '& .MuiSwitch-root': { 
                                                scale: '0.7'
                                            }
                                        }}>
                                            <Typography 
                                                variant="caption" 
                                                sx={{ 
                                                    color: product.enable_sizes ? '#9C27B0' : '#b0a199',
                                                    fontSize: '0.7rem',
                                                    cursor: 'pointer',
                                                    fontWeight: product.enable_sizes ? 600 : 400,
                                                    '&:hover': {
                                                        opacity: 0.8
                                                    }
                                                }}
                                                onClick={(e) => handleEnableSizesChange(product, !product.enable_sizes, e)}
                                            >
                                                Tallas
                                            </Typography>
                                            <Switch
                                                checked={product.enable_sizes}
                                                onChange={(e) => handleEnableSizesChange(product, e.target.checked, e)}
                                                color="secondary"
                                                size="small"
                                            />
                                        </Box>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            '& .MuiSwitch-root': { 
                                                scale: '0.7'
                                            }
                                        }}>
                                            <Typography 
                                                variant="caption" 
                                                sx={{ 
                                                    color: product.enable_colors ? '#FF9800' : '#b0a199',
                                                    fontSize: '0.7rem',
                                                    cursor: 'pointer',
                                                    fontWeight: product.enable_colors ? 600 : 400,
                                                    '&:hover': {
                                                        opacity: 0.8
                                                    }
                                                }}
                                                onClick={(e) => handleEnableColorsChange(product, !product.enable_colors, e)}
                                            >
                                                Colores
                                            </Typography>
                                            <Switch
                                                checked={product.enable_colors}
                                                onChange={(e) => handleEnableColorsChange(product, e.target.checked, e)}
                                                color="warning"
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ProductFormDialog
                open={openDialog}
                onClose={handleCloseDialog}
                product={selectedProduct}
                categories={categories}
                colors={colors}
                sizes={sizes}
            />

            <ProductImagesDialog
                open={openImagesDialog}
                onClose={() => setOpenImagesDialog(false)}
                product={selectedProductForImages}
                onImageDelete={fetchInitialData}
            />
        </Box>
    );
}

export default React.memo(ProductsPanel, (prevProps, nextProps) => {
    return true; // Solo se actualizará cuando las props cambien explícitamente
});