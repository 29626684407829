import React, { useState } from 'react';
import {
    Box,
    Paper,
    TextField,
    Button,
    Typography,
    Alert
} from '@mui/material';

function LoginAdmin({ onLoginSuccess }) {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        
        // Verificar credenciales contra las variables de entorno
        if (
            credentials.username === process.env.REACT_APP_ADMIN_USERNAME &&
            credentials.password === process.env.REACT_APP_ADMIN_PASSWORD
        ) {
            // Crear un token simple (puedes hacerlo más seguro si lo deseas)
            const token = btoa(`${credentials.username}:${Date.now()}`);
            localStorage.setItem('adminToken', token);
            onLoginSuccess();
        } else {
            setError('Credenciales inválidas');
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#fafafa'
        }}>
            <Paper sx={{
                p: 4,
                width: '100%',
                maxWidth: 400,
                borderRadius: 2,
                boxShadow: '8px 8px 16px #e1ddd9, -8px -8px 16px #ffffff'
            }}>
                <Typography variant="h5" sx={{ mb: 3, color: '#5a4a42', textAlign: 'center' }}>
                    Acceso Administrativo
                </Typography>
                <form onSubmit={handleLogin}>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <TextField
                        fullWidth
                        label="Usuario"
                        margin="normal"
                        value={credentials.username}
                        onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                    />
                    <TextField
                        fullWidth
                        label="Contraseña"
                        type="password"
                        margin="normal"
                        value={credentials.password}
                        onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                    />
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{
                            mt: 3,
                            backgroundColor: '#5a4a42',
                            '&:hover': {
                                backgroundColor: '#4a3a32'
                            }
                        }}
                    >
                        Iniciar Sesión
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default LoginAdmin; 