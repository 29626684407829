import React from 'react';
import {
    Routes,
    Route,
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";

import LandingPage from '../components/landingPage';
import Products from '../components/products';
import AdminLayout from '../components/admin/AdminLayout';
import HowToBuy from '../components/HowToBuy';
import ProductDetailPage from '../components/ProductDetailPage';
import { recordVisit } from '../services/stats';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import PrivacyPolicy from '../components/PrivacyPolicy';
function VisitTracker() {
    const location = useLocation();

    React.useEffect(() => {
        const visit = async () => {
            try {
                await recordVisit(location.pathname);
            } catch (error) {
                console.error('Error al registrar visita:', error);
            }
        };

        visit();
    }, [location.pathname]);

    return null;
}

// Componente wrapper que incluye el VisitTracker
function AppRoutes() {
    return (
        <>
            <VisitTracker />
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/products" element={displayPageCommon(<Products />)} />
                <Route path="/product/:id" element={displayPageCommon(<ProductDetailPage />)} />
                <Route path="/admin" element={<AdminLayout />} />
                <Route path="/how-to-buy" element={displayPageCommon(<HowToBuy />)} />
                <Route path="/privacy-policy" element={displayPageCommon(<PrivacyPolicy />)} />
            </Routes>
        </>
    );
}

const Rutas = () => {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
}

function displayPageCommon(children) {
    return (
        <>
            <CssBaseline />
            <Header />
            {children}
            <Footer />
        </>)
}

export default Rutas;