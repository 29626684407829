import React, { useEffect, useRef, useState } from 'react';
import {
    Container, Typography, Grid, Box, CssBaseline,
    ThemeProvider, createTheme, AppBar, Toolbar
} from '@mui/material';
import { styled } from '@mui/system';
import { motion, useAnimation, useViewportScroll, useTransform } from 'framer-motion';
import { useSettings } from '../context/SettingsContext';
import Footer from './Footer';
const theme = createTheme({
    palette: {
        background: {
            default: '#f8f4f1', // Soft cream
        },
        text: {
            primary: '#5a4a42', // Warm brown
            secondary: '#b0a199', // Muted pink-brown
        },
        primary: {
            main: '#d7c0ae', // Soft beige
        },
    },
    typography: {
        fontFamily: 'Georgia, serif',
    },
});

const NeumorphicBox = styled(motion.div)(({ theme }) => ({
    backgroundColor: '#f8f4f1',
    borderRadius: '15px',
    boxShadow: '8px 8px 16px #e1ddd9, -8px -8px 16px #ffffff, inset 2px 2px 5px #e1ddd9, inset -2px -2px 5px #ffffff',
    padding: '20px',
    margin: '10px 0',
    transition: 'all 0.3s ease',
}));

const NeumorphicButton = styled(motion.button)(({ theme }) => ({
    borderRadius: '25px',
    padding: '10px 20px',
    backgroundColor: '#f8f4f1',
    color: '#5a4a42',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    boxShadow: '4px 4px 8px #e1ddd9, -4px -4px 8px #ffffff, inset 1px 1px 3px #ffffff, inset -1px -1px 3px #e1ddd9',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#f0ece9',
        boxShadow: '2px 2px 4px #e1ddd9, -2px -2px 4px #ffffff, inset 2px 2px 5px #e1ddd9, inset -2px -2px 5px #ffffff',
    },
}));

const LogoMain = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const LogoLetter = styled('span')({
    fontSize: '7rem',
    fontWeight: 'bold',
});

const LogoBoutique = styled('span')({
    fontSize: '0.6rem',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    marginLeft: '5px',
    marginRight: '5px',
    transform: 'rotate(180deg)',
});

const LogoName = styled('span')({
    fontSize: '0.7rem',
    marginTop: '-45px',
    marginBottom: "10px",
    color: '#b0a199',
});

const LogoWrapper = styled(motion.div)(({ theme }) => ({
    fontFamily: 'Georgia, serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#5a4a42',
}));

const Logo = () => (
    <LogoWrapper
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
    >
        <LogoMain>
            <LogoLetter>E</LogoLetter>
            <LogoBoutique>BOUTIQUE</LogoBoutique>
            <LogoLetter>P</LogoLetter>
        </LogoMain>
        <LogoName>ELIANA PALMERO</LogoName>
    </LogoWrapper>
);

const VideoSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(248, 244, 241, 0.2)', // Reduced opacity for better video visibility
}));

const Section = styled(Box)(({ theme, bgcolor }) => ({
    padding: '120px 0', // Increased padding
    backgroundColor: bgcolor,
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '20px', // Increased height for more noticeable separation
        background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.05))',
    },
}));

function AnimatedSection({ children, threshold = 0.1 }) {
    const controls = useAnimation();
    const ref = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const currentRef = ref.current; // Guardamos la referencia en una variable
        
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                    controls.start('visible');
                }
            },
            { threshold }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) { // Usamos la variable guardada en lugar de ref.current
                observer.unobserve(currentRef);
            }
        };
    }, [controls, isVisible, threshold]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 }
            }}
            transition={{ duration: 0.8, ease: "easeOut" }}
        >
            {children}
        </motion.div>
    );
}

function ParallaxSection({ children }) {
    const { scrollY } = useViewportScroll();
    const y = useTransform(scrollY, [0, 300], [0, -50]);

    return (
        <motion.div style={{ y }}>
            {children}
        </motion.div>
    );
}

function EpBoutiqueLanding() {
    const { settings} = useSettings();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static" sx={{ backgroundColor: '#f8f4f1', boxShadow: 'none' }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <Logo />
                </Toolbar>
            </AppBar>

            {/* Video Section */}
            <VideoSection>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '-1'
                    }}
                >
                    {settings.find(s => s.key_name === 'url_video_principal')?.value && (
                        <source src={`${settings.find(s => s.key_name === 'url_video_principal')?.value}`} type="video/mp4" />
                    )}
                </video>
                <VideoOverlay>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 3, delay: 0 }}
                    >
                        <Typography variant="h2" component="h1" gutterBottom sx={{ color: '#ffffff', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
                            Bienvenido a EPBoutique
                        </Typography>
                        <Typography variant="h5" sx={{ mb: 4, color: '#ffffff', textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}>
                            Descubre nuestra colección de joyas, accesorios y mucho más.
                        </Typography>
                    </motion.div>
                </VideoOverlay>
            </VideoSection>

            {/* About Us Section */}
            <Section bgcolor="#f0ece9">
                <Container maxWidth="lg">
                    <AnimatedSection>
                        <ParallaxSection>
                            <NeumorphicBox
                                whileHover={{ scale: 1.02 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Typography variant="h3" component="h2" gutterBottom sx={{ color: '#5a4a42', textAlign: 'center' }}>
                                    Sobre Nosotros
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    sx={{ 
                                        color: '#5a4a42', 
                                        textAlign: 'center',
                                        whiteSpace: 'pre-wrap'
                                    }}
                                    dangerouslySetInnerHTML={{ 
                                        __html: settings.find(s => s.key_name === 'sobre_nosotros')?.value || ''
                                    }}
                                />
                            </NeumorphicBox>
                        </ParallaxSection>
                    </AnimatedSection>
                </Container>
            </Section>

            {/* Our Products Section */}
            <Section bgcolor="#f8f4f1">
                <Container 
                    maxWidth="lg"
                    sx={{
                        px: { xs: 1, md: 3 }
                    }}
                >
                    <AnimatedSection>
                        <Typography 
                            variant="h3" 
                            component="h2" 
                            gutterBottom 
                            sx={{ 
                                color: '#5a4a42', 
                                textAlign: 'center', 
                                mb: 4
                            }}
                        >
                            Nuestros Productos
                        </Typography>
                        <Grid 
                            container 
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ 
                                maxWidth: { xs: '350px', md: '900px' },
                                margin: '0 auto',
                                padding: { xs: '0 8px', md: 0 }
                            }}
                        >
                            <Grid item xs={12} md={4}>
                                <NeumorphicBox
                                    whileHover={{ scale: 1.05, rotate: 2 }}
                                    transition={{ duration: 0.3 }}
                                    sx={{ 
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: { xs: 2, md: 0 }
                                    }}
                                >
                                    <img
                                        src={`${settings.find(s => s.key_name === 'img_1_nuestros_productos_landingpage')?.value}`}
                                        alt="Producto 1"
                                        style={{ 
                                            width: '100%',
                                            height: '300px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </NeumorphicBox>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <NeumorphicBox
                                    whileHover={{ scale: 1.05, rotate: 2 }}
                                    transition={{ duration: 0.3 }}
                                    sx={{ 
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: { xs: 2, md: 0 }
                                    }}
                                >
                                    <img
                                        src={`${settings.find(s => s.key_name === 'img_2_nuestros_productos_landingpage')?.value}`}
                                        alt="Producto 2"
                                        style={{ 
                                            width: '100%',
                                            height: '300px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </NeumorphicBox>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <NeumorphicBox
                                    whileHover={{ scale: 1.05, rotate: 2 }}
                                    transition={{ duration: 0.3 }}
                                    sx={{ 
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={`${settings.find(s => s.key_name === 'img_3_nuestros_productos_landingpage')?.value}`}
                                        alt="Producto 3"
                                        style={{ 
                                            width: '100%',
                                            height: '300px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </NeumorphicBox>
                            </Grid>
                        </Grid>
                    </AnimatedSection>
                </Container>
            </Section>

            {/* Visit Our Store Section */}
            <Section bgcolor="#e6e0d8">
                <Container maxWidth="lg">
                    <AnimatedSection>
                        <NeumorphicBox sx={{ textAlign: 'center' }}>
                            <Typography variant="h3" component="h2" gutterBottom sx={{ color: '#5a4a42' }}>
                                Visita Nuestro catálogo
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#5a4a42', mb: 4, whiteSpace: 'pre-wrap' }}>
                                {settings.find(s => s.key_name === 'texto_visita_nuestro_catalogo')?.value}	
                            </Typography>
                            <NeumorphicButton
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <a href="/products" style={{ textDecoration: 'none', color: '#5a4a42' }}>
                                    Ir a al catálogo
                                </a>
                            </NeumorphicButton>
                        </NeumorphicBox>
                    </AnimatedSection>
                </Container>
            </Section>

            <Footer bgColor="#f8f4f1" />
        </ThemeProvider>
    );
}

export default EpBoutiqueLanding;