import React, { useMemo } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    IconButton,
    CardActionArea,
    useMediaQuery,
    Chip
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useSettings } from '../context/SettingsContext';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function ProductCard({ product, sx }) {
    const { settings } = useSettings();
    const { isInCart, addToCart, removeFromCart } = useCart();
    const navigate = useNavigate();
    const isFavorite = isInCart(product.id);
    const isMobile = useMediaQuery('(max-width:600px)');

    const sortedImages = useMemo(() => {
        if (!product.imagenes) return [];
        return [...product.imagenes].sort((a, b) => {
            if (a.is_main) return -1;
            if (b.is_main) return 1;
            return 0;
        });
    }, [product.imagenes]);

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        if (isFavorite) {
            removeFromCart(product.id);
        } else {
            addToCart(product);
        }
    };

    const handleWhatsAppShare = (e) => {
        e.stopPropagation();
        const message = `¡Mira este producto! ${product.nombre} - ${window.location.origin}/product/${product.id}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleClick = () => {
        navigate(`/product/${product.id}`);
    };

    return (
        <Card 
            sx={{ 
                ...sx,
                position: 'relative',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                backgroundColor: '#fff',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-5px)'
                },
                '& .swiper-button-next, & .swiper-button-prev': {
                    color: '#fff',
                    background: 'rgba(90, 74, 66, 0.5)',
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    '&::after': {
                        fontSize: '12px',
                        fontWeight: 'bold'
                    },
                    display: { xs: 'none', md: 'flex' }
                },
                '& .swiper-button-next': {
                    right: '5px'
                },
                '& .swiper-button-prev': {
                    left: '5px'
                },
                '& .swiper-pagination-bullet': {
                    backgroundColor: '#fff',
                    opacity: 0.7,
                    '&-active': {
                        backgroundColor: '#fff',
                        opacity: 1
                    }
                }
            }}
        >
            <CardActionArea onClick={handleClick}>
                <Box sx={{ position: 'relative' }}>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        navigation={!isMobile}
                        pagination={{ 
                            clickable: true,
                            dynamicBullets: true
                        }}
                        style={{ borderRadius: '15px 15px 0 0' }}
                        onSwiper={(swiper) => {
                            const prevButton = swiper.navigation.prevEl;
                            const nextButton = swiper.navigation.nextEl;
                            if (prevButton) prevButton.addEventListener('click', e => e.stopPropagation());
                            if (nextButton) nextButton.addEventListener('click', e => e.stopPropagation());
                        }}
                    >
                        {sortedImages.map((imagen, index) => (
                            <SwiperSlide key={index}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={imagen.url || imagen}
                                    alt={`${product.nombre} - imagen ${index + 1}`}
                                    sx={{ objectFit: 'cover' }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Box sx={{ 
                        position: 'absolute', 
                        top: 8, 
                        right: 8, 
                        zIndex: 2,
                        display: 'flex',
                        gap: 1
                    }}>
                        <IconButton 
                            onClick={handleFavoriteClick}
                            sx={{ 
                                bgcolor: 'rgba(255,255,255,0.8)',
                                '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
                            }}
                        >
                            {isFavorite ? 
                                <FavoriteIcon sx={{ color: '#ff4081' }} /> : 
                                <FavoriteBorderIcon sx={{ color: '#5a4a42' }} />
                            }
                        </IconButton>
                        <IconButton 
                            onClick={handleWhatsAppShare}
                            sx={{ 
                                bgcolor: 'rgba(255,255,255,0.8)',
                                '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' }
                            }}
                        >
                            <WhatsAppIcon sx={{ color: '#25D366' }} />
                        </IconButton>
                    </Box>
                </Box>
                <CardContent sx={{ p: 2 }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            fontSize: '1rem',
                            fontWeight: 500,
                            color: '#5a4a42',
                            mb: 1
                        }}
                    >
                        {product.nombre}
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#b0a199',
                            fontWeight: 600,
                            fontSize: '1.1rem',
                            mb: 1
                        }}
                    >
                        {settings.find(setting => setting.key_name === 'moneda')?.value}{product.precio}
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap'
                    }}>
                        {product.is_new ===1 && <Chip
                            label="Nuevo"
                            size="small"
                            sx={{
                                backgroundColor: '#d7c0ae',
                                color: '#5a4a42',
                                fontSize: '0.7rem',
                                height: '20px'
                            }}
                        />}
                        {product.in_stock ===1  && <Chip
                            label="En Stock"
                            size="small"
                            sx={{
                                backgroundColor: '#f8f4f1',
                                color: '#5a4a42',
                                fontSize: '0.7rem',
                                height: '20px',
                                border: '1px solid #d7c0ae'
                            }}
                        />}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ProductCard; 