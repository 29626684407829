import React, { useState } from 'react';
import { AppBar, Toolbar, Drawer, IconButton, Badge } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import '../styles/fonts.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from '../context/CartContext';
import WishlistDrawer from './WishlistDrawer';
import { motion} from 'framer-motion';

const LogoMain = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const LogoLetter = styled('span')({
    fontSize: '7rem',
    fontWeight: 'bold',
});

const LogoBoutique = styled('span')({
    fontSize: '0.6rem',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    marginLeft: '5px',
    marginRight: '5px',
    transform: 'rotate(180deg)',
});

const LogoName = styled('span')({
    fontSize: '0.7rem',
    marginTop: '-45px',
    marginBottom: "10px",
    color: '#b0a199',
});

const LogoWrapper = styled(motion.div)(({ theme }) => ({
    fontFamily: 'Georgia, serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#5a4a42',
}));


function Header() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
    const location = useLocation();
    const { cartCount } = useCart();
    
    const showMenu = !location.pathname.startsWith('/admin');

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };


    const Logo = () => (
        <LogoWrapper>
            <LogoMain>
                <LogoLetter>E</LogoLetter>
                <LogoBoutique>BOUTIQUE</LogoBoutique>
                <LogoLetter>P</LogoLetter>
            </LogoMain>
            <LogoName>ELIANA PALMERO</LogoName>
        </LogoWrapper>
    );

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: '#f8f4f1', boxShadow: 'none' }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    {showMenu && (
                        <>
           
                            <IconButton
                                onClick={() => setCartDrawerOpen(true)}
                                sx={{ 
                                    position: 'absolute', 
                                    right: 16,
                                    color: '#5a4a42',
                                    '&:hover': {
                                        backgroundColor: 'rgba(215,192,174,0.2)'
                                    }
                                }}
                            >
                                <Badge 
                                    badgeContent={cartCount} 
                                    color="error"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: '#e57373',
                                            color: '#fff'
                                        }
                                    }}
                                >
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>
                        </>
                    )}
                    <Logo />
                </Toolbar>
            </AppBar>
            
            {showMenu && (
                <>
                    <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={toggleDrawer}
                    >
                        {/* Contenido del drawer */}
                    </Drawer>

                    <WishlistDrawer 
                        open={cartDrawerOpen} 
                        onClose={() => setCartDrawerOpen(false)} 
                    />
                </>
            )}
        </>
    );
}

export default Header; 