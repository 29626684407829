import React, { useState, useEffect } from 'react';
import { 
    Box, Typography, TextField, Select, MenuItem, InputAdornment,
    IconButton, FormControl, InputLabel, Chip, Checkbox, ListItemText,
    FormControlLabel, Switch, Slider
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const FilterSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#f8f4f1',
    padding: theme.spacing(3),
    borderRadius: '15px',
    boxShadow: '8px 8px 16px #e1ddd9, -8px -8px 16px #ffffff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const SearchField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#f8f4f1',
        borderRadius: '10px',
        boxShadow: 'inset 2px 2px 5px #e1ddd9, inset -2px -2px 5px #ffffff',
        '& fieldset': {
            border: 'none',
        },
    },
});

const ElegantSelect = styled(Select)({
    backgroundColor: '#f8f4f1',
    borderRadius: '10px',
    boxShadow: 'inset 2px 2px 5px #e1ddd9, inset -2px -2px 5px #ffffff',
    '& fieldset': {
        border: 'none',
    },
    '& .MuiSelect-select': {
        paddingRight: '32px',
        minHeight: 'auto !important',
        height: 'auto',
    }
});

const FavoriteSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#d7c0ae',
        '&:hover': {
            backgroundColor: 'rgba(215, 192, 174, 0.08)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#d7c0ae',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#e8e1d9',
    }
}));

const PriceSlider = styled(Slider)(({ theme }) => ({
    color: '#d7c0ae',
    height: 3,
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: '#f8f4f1',
        border: '2px solid #d7c0ae',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: '0.75rem',
        background: '#5a4a42',
        padding: '2px 6px',
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#b0a199',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#b0a199',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));

function FiltersContent({ 
    searchTerm, 
    onSearchChange, 
    categories, 
    selectedCategories, 
    onCategoryChange,
    showOnlyFavorites,
    onFavoritesChange,
    priceRange = [0, 1000],
    onPriceRangeChange,
    onClose,
    isMobile 
}) {
    const [searchCategory, setSearchCategory] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [inputValues, setInputValues] = useState({
        min: priceRange[0],
        max: priceRange[1]
    });

    const filteredCategories = categories.filter(category =>
        category.nombre.toLowerCase().includes(searchCategory.toLowerCase())
    );

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        const newValue = typeof value === 'string' ? value.split(',') : value;
        onCategoryChange(newValue);
    };

    const handleSearchFocus = () => {
        setIsSearching(true);
    };

    const handleSearchBlur = () => {
        setIsSearching(false);
    };

    const handleSearchClick = (e) => {
        e.stopPropagation();
    };

    const handleSearchKeyDown = (e) => {
        e.stopPropagation();
    };

    const handlePriceChange = (event, newValue) => {
        onPriceRangeChange(newValue);
    };

    const handlePriceInputChange = (type) => (event) => {
        const value = Number(event.target.value);
        setInputValues(prev => ({
            ...prev,
            [type]: value
        }));
    };

    const handleInputBlur = (type) => () => {
        let newMin = inputValues.min;
        let newMax = inputValues.max;

        // Validaciones
        if (type === 'min') {
            newMin = Math.max(0, Math.min(newMin, priceRange[1]));
        } else {
            newMax = Math.max(priceRange[0], Math.min(newMax, 1000));
        }

        const newRange = [type === 'min' ? newMin : priceRange[0], 
                         type === 'max' ? newMax : priceRange[1]];
        
        onPriceRangeChange(newRange);
        setInputValues({ min: newRange[0], max: newRange[1] });
    };

    // Actualizar inputs cuando cambia el slider
    useEffect(() => {
        setInputValues({
            min: priceRange[0],
            max: priceRange[1]
        });
    }, [priceRange]);

    return (
        <FilterSection>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                    Filtros
                </Typography>
                {isMobile && (
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>

            <Box sx={{ mb: 3 }}>
                <FormControlLabel
                    control={
                        <FavoriteSwitch
                            checked={showOnlyFavorites}
                            onChange={(e) => onFavoritesChange(e.target.checked)}
                        />
                    }
                    label={
                        <Typography sx={{ 
                            color: '#5a4a42',
                            fontSize: '0.9rem',
                            fontWeight: showOnlyFavorites ? 'medium' : 'normal'
                        }}>
                            Solo favoritos
                        </Typography>
                    }
                />
            </Box>

            <SearchField
                fullWidth
                placeholder="Buscar productos..."
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#5a4a42' }} />
                        </InputAdornment>
                    ),
                }}
            />

            <Box sx={{ mb: 3 }}>
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        color: '#5a4a42',
                        mb: 2,
                        fontSize: '0.85rem',
                        fontWeight: 500
                    }}
                >
                    Rango de precios
                </Typography>
                
                <Box sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    mb: 2,
                    alignItems: 'center'
                }}>
                    <TextField
                        size="small"
                        value={inputValues.min}
                        onChange={handlePriceInputChange('min')}
                        onBlur={handleInputBlur('min')}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            sx: { 
                                fontSize: '0.75rem',
                                '& input': {
                                    padding: '4px 8px',
                                }
                            }
                        }}
                        sx={{
                            width: '100px',
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#f8f4f1',
                                '& fieldset': {
                                    borderColor: '#d7c0ae',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#b0a199',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#5a4a42',
                                },
                            },
                        }}
                    />
                    <Typography sx={{ color: '#5a4a42' }}>-</Typography>
                    <TextField
                        size="small"
                        value={inputValues.max}
                        onChange={handlePriceInputChange('max')}
                        onBlur={handleInputBlur('max')}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            sx: { 
                                fontSize: '0.75rem',
                                '& input': {
                                    padding: '4px 8px',
                                }
                            }
                        }}
                        sx={{
                            width: '100px',
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#f8f4f1',
                                '& fieldset': {
                                    borderColor: '#d7c0ae',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#b0a199',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#5a4a42',
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ px: 1 }}>
                    <PriceSlider
                        value={priceRange}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        step={10}
                        valueLabelFormat={(value) => `${value}€`}
                    />
                </Box>
            </Box>

            <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id="categories-label" sx={{ color: '#5a4a42' }}>Categorías</InputLabel>
                <ElegantSelect
                    labelId="categories-label"
                    multiple
                    value={selectedCategories || []}
                    onChange={handleCategoryChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                const category = categories.find(cat => cat.id === value);
                                return category ? (
                                    <Chip 
                                        key={value} 
                                        label={category.nombre}
                                        size="small"
                                        sx={{ 
                                            backgroundColor: '#d7c0ae',
                                            color: '#5a4a42',
                                            fontSize: '0.75rem',
                                            height: '24px'
                                        }}
                                    />
                                ) : null;
                            })}
                        </Box>
                    )}
                    onKeyDown={(e) => {
                        if (isSearching) {
                            e.preventDefault();
                        }
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                bgcolor: '#f8f4f1',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                borderRadius: '0 0 10px 10px',
                                mt: 0,
                                maxHeight: '300px',
                                overflowY: 'auto',
                                border: '1px solid #e1ddd9',
                                '& .MuiMenuItem-root': {
                                    padding: '4px 8px',
                                    minHeight: '32px',
                                    borderBottom: '1px solid #e1ddd9',
                                    '&:last-child': {
                                        borderBottom: 'none'
                                    },
                                    '&:hover': {
                                        bgcolor: '#e6e0d8',
                                    },
                                    '&.Mui-focused': {
                                        bgcolor: 'transparent',
                                    },
                                    '&.Mui-selected': {
                                        bgcolor: 'transparent',
                                    }
                                }
                            }
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        }
                    }}
                >
                    <Box 
                        sx={{ 
                            width: '100%', 
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            bgcolor: '#f8f4f1',
                            p: 1
                        }}
                        onClick={handleSearchClick}
                    >
                        <SearchField
                            fullWidth
                            size="small"
                            placeholder="Buscar categorías..."
                            value={searchCategory}
                            onChange={(e) => setSearchCategory(e.target.value)}
                            onClick={handleSearchClick}
                            onKeyDown={handleSearchKeyDown}
                            onFocus={handleSearchFocus}
                            onBlur={handleSearchBlur}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: '#5a4a42', fontSize: '0.9rem' }} />
                                    </InputAdornment>
                                ),
                                sx: { 
                                    fontSize: '0.75rem',
                                    '& .MuiInputBase-input': {
                                        padding: '6px 10px',
                                    }
                                }
                            }}
                            autoComplete="off"
                        />
                    </Box>
                    {filteredCategories.map((category) => (
                        <MenuItem 
                            key={category.id} 
                            value={category.id}
                            sx={{
                                padding: '4px 8px',
                                minHeight: '32px',
                                '&:focus': {
                                    backgroundColor: 'transparent',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'transparent',
                                },
                                '&:hover': {
                                    backgroundColor: '#f0ece9',
                                }
                            }}
                        >
                            <Checkbox 
                                checked={selectedCategories ? selectedCategories.indexOf(category.id) > -1 : false}
                                size="small"
                                sx={{
                                    padding: '4px',
                                    color: '#5a4a42',
                                    mr: 1,
                                    '&.Mui-checked': {
                                        color: '#5a4a42',
                                    }
                                }}
                            />
                            <ListItemText 
                                primary={category.nombre}
                                sx={{ 
                                    '& .MuiTypography-root': {
                                        fontSize: '0.75rem'
                                    }
                                }}
                            />
                        </MenuItem>
                    ))}
                </ElegantSelect>
            </FormControl>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ mt: 3, p: 2, bgcolor: 'rgba(215, 192, 174, 0.1)', borderRadius: '10px' }}>
                <Typography variant="body2" sx={{ color: '#5a4a42' }}>
                    {selectedCategories?.length || 0} categorías seleccionadas
                </Typography>
                <Typography variant="body2" sx={{ color: '#5a4a42', mt: 1 }}>
                    {filteredCategories.length} categorías disponibles
                </Typography>
            </Box>
        </FilterSection>
    );
}

export default FiltersContent; 