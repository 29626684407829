export const getCategories = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}categories`);
    const data = await response.json();
    return data;
}

export const createCategory = async (category) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}categories`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(category)
    });
    return response.json();
}

export const updateCategory = async (id, category) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}categories/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(category)
    });
    return response.json();
}

export const deleteCategory = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}categories/${id}`, {
        method: 'DELETE'
    });
    return response.json();
}
