import React from 'react';
import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useSettings } from '../context/SettingsContext';

function Footer({ bgColor = '#f8f4f1' }) {
    const { settings } = useSettings();
    const whatsappNumber = settings?.find(s => s.key_name === 'whatsapp_number')?.value || '34600000000';
    const instagramUrl = settings?.find(s => s.key_name === 'instagram_url')?.value || 'epboutique';

    return (
        <Box
            component="footer"
            sx={{
                bgcolor: bgColor,
                pt: 4,
                pb: 6,
                mt: 'auto'
            }}
        >
            <Container maxWidth="lg">
                <Grid 
                    container 
                    spacing={4} 
                    justifyContent="center"
                    sx={{
                        maxWidth: { xs: '350px', md: '100%' },
                        margin: '0 auto'
                    }}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography 
                            variant="h6" 
                            color="#5a4a42" 
                            gutterBottom
                            sx={{ textAlign: { xs: 'center', md: 'left' } }}
                        >
                            Contacto
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: { xs: 'center', md: 'flex-start' }
                        }}>
                            <IconButton 
                                onClick={() => window.open(`https://wa.me/${whatsappNumber}`, '_blank')}
                                sx={{ 
                                    color: '#b0a199',
                                    '&:hover': { 
                                        color: '#5a4a42',
                                        transform: 'scale(1.1)'
                                    },
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography 
                            variant="h6" 
                            color="#5a4a42" 
                            gutterBottom
                            sx={{ textAlign: { xs: 'center', md: 'left' } }}
                        >
                            Síguenos
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: { xs: 'center', md: 'flex-start' }
                        }}>
                            <IconButton 
                                onClick={() => window.open(`${instagramUrl}`, '_blank')}
                                sx={{ 
                                    color: '#b0a199',
                                    '&:hover': { 
                                        color: '#5a4a42',
                                        transform: 'scale(1.1)'
                                    },
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <InstagramIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography 
                            variant="h6" 
                            color="#5a4a42" 
                            gutterBottom
                            sx={{ textAlign: { xs: 'center', md: 'left' } }}
                        >
                            Ubicación
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: { xs: 'center', md: 'flex-start' }
                        }}>
                            <IconButton 
                                onClick={() => window.open('https://maps.google.com/?q=Madrid', '_blank')}
                                sx={{ 
                                    color: '#b0a199',
                                    '&:hover': { 
                                        color: '#5a4a42',
                                        transform: 'scale(1.1)'
                                    },
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <LocationOnIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ position: 'relative' }}>
                    <Typography 
                        variant="body2" 
                        color="#b0a199" 
                        align="center"
                        sx={{ 
                            mt: 4,
                            pt: 3,
                            borderTop: '1px solid rgba(176, 161, 153, 0.2)'
                        }}
                    >
                        © {new Date().getFullYear()} EP Boutique. Todos los derechos reservados.
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: -20,
                            color: '#5a4a42',
                            fontSize: '0.65rem',
                            fontFamily: "'Cormorant Garamond', serif",
                            letterSpacing: '1px',
                            fontWeight: 400,
                            opacity: 0.8,
                            '&:hover': {
                                opacity: 1,
                                color: '#3d322d',
                                transition: 'all 0.3s ease'
                            }
                        }}
                    >
                        Design by Jezer
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer; 