import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import {
    People,
    Visibility,
    Delete as DeleteIcon
} from '@mui/icons-material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from 'recharts';

import { getStats, clearStats } from '../../services/stats';

function DashboardPanel() {
    const [stats, setStats] = useState({
        totalVisits: 0,
        uniqueVisitors: 0,
        last24hVisits: 0,
        visitsByPage: [],
        visitorsTrend: []
    });
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const fetchVisitStats = async () => {
            try {
                const data = await getStats();
                
                setStats({
                    totalVisits: data.totalVisits.total_visits,
                    uniqueVisitors: data.totalVisits.unique_visitors,
                    last24hVisits: data.totalVisits.last_24h_visits,
                    visitsByPage: data.visitsByPage,
                    visitorsTrend: data.visitorsTrend || []
                });
            } catch (error) {
                console.error('Error al obtener estadísticas:', error);
            }
        };

        fetchVisitStats();
        const interval = setInterval(fetchVisitStats, 5 * 60 * 1000);
        
        return () => clearInterval(interval);
    }, []);

    const handleClearStats = async () => {
        try {
            await clearStats();
            setOpenDialog(false);
            const data = await getStats();
            setStats({
                totalVisits: data.totalVisits.total_visits,
                uniqueVisitors: data.totalVisits.unique_visitors,
                last24hVisits: data.totalVisits.last_24h_visits,
                visitsByPage: data.visitsByPage,
                visitorsTrend: data.visitorsTrend || []
            });
        } catch (error) {
            console.error('Error al limpiar estadísticas:', error);
        }
    };

    const StatCard = ({ title, value, icon, color }) => (
        <Card sx={{ 
            height: '100%',
            backgroundColor: '#fff',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 6px 12px rgba(0,0,0,0.15)'
            }
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {React.cloneElement(icon, { 
                        sx: { 
                            fontSize: 40, 
                            color: color,
                            backgroundColor: `${color}15`,
                            p: 1,
                            borderRadius: 2
                        } 
                    })}
                </Box>
                <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
                    {value}
                </Typography>
                <Typography color="text.secondary">
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );

    // Colores para los gráficos
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

    // Función para formatear los nombres de las rutas
    const formatPageName = (path) => {
        switch (path) {
            case '/':
                return 'Landing Page';
            case '/admin':
                return 'Panel Admin';
            case '/products':
                return 'Productos';
            case '/how-to-buy':
                return 'Cómo Comprar';
            default:
                return path;
        }
    };

    // Transformar los datos con nombres formateados
    const formattedData = stats.visitsByPage.map(page => ({
        name: formatPageName(page.page_path),
        value: parseInt(page.visits),
        originalPath: page.page_path
    }));

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4" sx={{ color: '#5a4a42' }}>
                    Panel de Control
                </Typography>
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => setOpenDialog(true)}
                >
                    Limpiar Estadísticas
                </Button>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas Totales"
                        value={stats.totalVisits}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitantes Únicos"
                        value={stats.uniqueVisitors}
                        icon={<People />}
                        color="#607d8b"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas Últimas 24 Horas"
                        value={stats.last24hVisits}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas por Página"
                        value={stats.visitsByPage.length}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
            </Grid>

            {/* Sección de gráficos */}
            <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, height: '400px' }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#5a4a42' }}>
                            Distribución de Visitas por Página
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={formattedData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {formattedData.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={COLORS[index % COLORS.length]} 
                                        />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value, name) => [value, name]} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, height: '400px' }}>
                        <Typography variant="h6" sx={{ mb: 2, color: '#5a4a42' }}>
                            Visitas por Página
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={formattedData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 100
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="name"
                                    angle={-45}
                                    textAnchor="end"
                                    height={100}
                                    interval={0}
                                    tick={{ 
                                        fontSize: 12,
                                        dx: -10,
                                        dy: 50
                                    }}
                                />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name="Visitas">
                                    {formattedData.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirmar Limpieza</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar todas las estadísticas? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={handleClearStats} color="error" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DashboardPanel; 