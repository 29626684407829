import React, { useState } from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ButtonGroup,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useCart } from '../context/CartContext';
import html2canvas from 'html2canvas';
import { useSettings } from '../context/SettingsContext';
import SendIcon from '@mui/icons-material/Send';
import WishlistSubmitDialog from './WishlistSubmitDialog';
import { createOrder } from '../services/orders';
export default function WishlistDrawer({ open, onClose }) {
    const { cart, removeFromCart, updateQuantity, clearCart, cartCount } = useCart();
    const { settings } = useSettings();
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    console.log('Cart products:', cart.map(p => ({
        id: p.id,
        nombre: p.nombre,
        precio: p.precio,
        precio_oferta: p.precio_oferta,
        tipo: {
            precio: typeof p.precio,
            precio_oferta: typeof p.precio_oferta
        }
    })));

    const formatPrice = (price) => {
        if (price === null || price === undefined) return '0';
        if (typeof price === 'object') return '0';
        return price.toString();
    };

    const handleQuantityChange = (productId, change) => {
        const product = cart.find(item => item.id === productId);
        if (product) {
            const newQuantity = (product.quantity || 1) + change;
            if (newQuantity > 0) {
                updateQuantity(productId, newQuantity);
            }
        }
    };

    const handleShareScreenshot = async () => {
        try {
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.background = '#f8f4f1';
            tempDiv.style.padding = '20px';
            tempDiv.style.width = '800px';

            tempDiv.innerHTML = `
                <div style="font-family: Arial, sans-serif;">
                    <div style="text-align: center; margin-bottom: 30px;">
                        <h1 style="color: #5a4a42;">Mi Lista de Deseos - EP Boutique</h1>
                    </div>
                    ${cart.map(product => `
                        <div style="background: white; padding: 20px; margin-bottom: 20px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                            <div style="display: flex; align-items: start;">
                                <img src="${product.imagenes[0]?.url}" 
                                    style="width: 100px; height: 100px; object-fit: cover; border-radius: 4px; margin-right: 20px;"
                                />
                                <div>
                                    <h2 style="color: #5a4a42; margin: 0 0 10px 0;">${product.nombre}</h2>
                                    <div style="margin: 0 0 10px 0;">
                                 
                                            <span style="color: #5a4a42; font-size: 18px; font-weight: bold;">
                                                ${settings.find(setting => setting.key_name === 'moneda')?.value} ${formatPrice(product.precio)}
                                            </span>
                                    </div>
                                    <p style="color: #5a4a42; margin: 0;">
                                        Cantidad: ${product.quantity || 1}
                                    </p>
                                </div>
                            </div>
                        </div>
                    `).join('')}
                    <div style="text-align: center; margin-top: 30px; color: #666;">
                        <p>Total de productos: ${cart.length}</p>
                        <p>EP Boutique</p>
                    </div>
                </div>
            `;

            document.body.appendChild(tempDiv);

            // Esperamos a que las imágenes se carguen
            await Promise.all(Array.from(tempDiv.getElementsByTagName('img')).map(img => {
                if (img.complete) return Promise.resolve();
                return new Promise(resolve => {
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            }));

            // Capturamos el contenido
            const canvas = await html2canvas(tempDiv, {
                scale: 2, // Mayor calidad
                backgroundColor: '#f8f4f1',
                logging: false,
                useCORS: true // Permitir imágenes de otros dominios
            });

            // Removemos el div temporal
            document.body.removeChild(tempDiv);

            // Convertimos el canvas a blob
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.9));

            // Creamos un archivo para compartir
            const file = new File([blob], 'lista-deseos-ep-boutique.jpg', { type: 'image/jpeg' });

            // Compartimos usando la Web Share API si está disponible
            if (navigator.share) {
                try {
                    await navigator.share({
                        files: [file],
                        title: 'Mi Lista de Deseos - EP Boutique',
                    });
                } catch (error) {
                    // Si falla el share, descargamos la imagen
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'lista-deseos-ep-boutique.jpg';
                    link.click();
                }
            } else {
                // Si no está disponible Web Share API, descargamos la imagen
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'lista-deseos-ep-boutique.jpg';
                link.click();
            }
        } catch (error) {
            console.error('Error al generar la captura:', error);
            alert('Hubo un error al generar la captura. Por favor, intenta de nuevo.');
        }
    };

    const handleSubmitOrder = async (formData) => {
        try {
            await createOrder(formData);
            setSubmitSuccess(true);
            setOpenSubmitDialog(false);
            // Opcional: limpiar el carrito después de enviar
            // clearCart();
        } catch (error) {
            console.error('Error al enviar el pedido:', error);
        }
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        width: { xs: '100%', sm: '600px' },
                        backgroundColor: '#f8f4f1'
                    }
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    {/* Header */}
                    <Box sx={{
                        p: 3,
                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                        backgroundColor: '#fff'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Typography variant="h5" sx={{
                                color: '#5a4a42',
                                fontWeight: 'bold'
                            }}>
                                Mi Lista de Deseos ({cartCount})
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Content */}
                    <Box sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 3
                    }}>
                        {cart.length === 0 ? (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 4
                            }}>
                                <ShoppingCartIcon sx={{ fontSize: 80, color: '#d7c0ae', mb: 2 }} />
                                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                                    Tu lista de deseos está vacía
                                </Typography>
                            </Box>
                        ) : (
                            <List sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                                {cart.map((product) => (
                                    <ListItem
                                        key={product.id}
                                        sx={{
                                            backgroundColor: '#fff',
                                            borderRadius: 2,
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                            p: 2,
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            alignItems: { xs: 'stretch', sm: 'flex-start' },
                                            gap: 2
                                        }}
                                    >
                                        <ListItemAvatar sx={{
                                            width: { xs: '100%', sm: 'auto' },
                                            m: 0
                                        }}>
                                            <Avatar
                                                src={product.imagenes[0]?.url}
                                                variant="rounded"
                                                sx={{
                                                    width: { xs: '100%', sm: 100 },
                                                    height: { xs: 200, sm: 100 },
                                                    mr: { xs: 0, sm: 3 }
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: 1,
                                            gap: 2,
                                            width: '100%'
                                        }}>
                                            <ListItemText
                                                primary={product.nombre}
                                                secondary={
                                                    <Box sx={{ mt: 1 }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            flexWrap: 'wrap'
                                                        }}>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    color: '#e57373',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                {settings.find(setting => setting.key_name === 'moneda')?.value} {formatPrice(product.precio)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                                primaryTypographyProps={{
                                                    variant: 'h6',
                                                    sx: { color: '#5a4a42', fontWeight: 'medium' }
                                                }}
                                            />
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: { xs: 'flex-start', sm: 'flex-start' },
                                                flexDirection: 'row',
                                                gap: 2,
                                                width: '100%'
                                            }}>
                                                <ButtonGroup size="large" sx={{
                                                    height: '40px',
                                                    width: 'auto'
                                                }}>
                                                    <Button
                                                        onClick={() => handleQuantityChange(product.id, -1)}
                                                        sx={{
                                                            minWidth: '40px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #d7c0ae !important'
                                                        }}
                                                    >
                                                        <RemoveIcon sx={{ color: '#5a4a42' }} />
                                                    </Button>
                                                    <Button
                                                        sx={{
                                                            minWidth: '60px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #d7c0ae !important',
                                                            color: '#5a4a42',
                                                            fontSize: '1.1rem'
                                                        }}
                                                        disableRipple
                                                    >
                                                        {product.quantity || 1}
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleQuantityChange(product.id, 1)}
                                                        sx={{
                                                            minWidth: '40px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #d7c0ae !important'
                                                        }}
                                                    >
                                                        <AddIcon sx={{ color: '#5a4a42' }} />
                                                    </Button>
                                                </ButtonGroup>
                                                <IconButton
                                                    onClick={() => removeFromCart(product.id)}
                                                    sx={{
                                                        color: '#e57373',
                                                        width: 'auto',
                                                        '&:hover': { backgroundColor: 'rgba(229,115,115,0.1)' }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>

                    {/* Footer */}
                    {cart.length > 0 && (
                        <Box sx={{
                            p: 3,
                            borderTop: '1px solid rgba(0,0,0,0.1)',
                            backgroundColor: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <Button
                                onClick={clearCart}
                                startIcon={<DeleteIcon />}
                                sx={{
                                    color: '#e57373',
                                    '&:hover': { backgroundColor: 'rgba(229,115,115,0.1)' }
                                }}
                            >
                                Limpiar lista
                            </Button>
                            <Box sx={{ display: 'flex', gap: { xs: 0.5, sm: 1 } }}>
                                <Button
                                    onClick={() => setOpenSubmitDialog(true)}
                                    variant="contained"
                                    startIcon={<SendIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />}
                                    sx={{ 
                                        backgroundColor: '#d7c0ae',
                                        color: '#ffffff',
                                        '&:hover': { 
                                            backgroundColor: '#c4ae9d' 
                                        },
                                        px: { xs: 1.5, sm: 3 },
                                        py: { xs: 0.5, sm: 1 },
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        boxShadow: 'none',
                                        '&:active': {
                                            boxShadow: 'none'
                                        },
                                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                        minWidth: { xs: 'auto', sm: '140px' }
                                    }}
                                >
                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        Enviar pedido
                                    </Box>
                                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        Enviar
                                    </Box>
                                </Button>
                                <Button
                                    onClick={handleShareScreenshot}
                                    variant="contained"
                                    startIcon={<WhatsAppIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />}
                                    sx={{ 
                                        backgroundColor: '#25D366',
                                        '&:hover': { 
                                            backgroundColor: '#1fab54' 
                                        },
                                        px: { xs: 1.5, sm: 3 },
                                        py: { xs: 0.5, sm: 1 },
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        boxShadow: 'none',
                                        '&:active': {
                                            boxShadow: 'none'
                                        },
                                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                        minWidth: { xs: 'auto', sm: '140px' }
                                    }}
                                >
                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        Compartir
                                    </Box>
                                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        Enviar
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>

            <WishlistSubmitDialog
                open={openSubmitDialog}
                onClose={() => setOpenSubmitDialog(false)}
                wishlist={cart}
                onSubmit={handleSubmitOrder}
            />

            {/* Snackbar para mostrar mensaje de éxito */}
            <Snackbar
                open={submitSuccess}
                autoHideDuration={6000}
                onClose={() => setSubmitSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSubmitSuccess(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    ¡Gracias! Nos pondremos en contacto contigo próximamente para formalizar tu pedido.
                </Alert>
            </Snackbar>
        </>
    );
} 