import React, { useState, useEffect, useMemo } from 'react';
import {
    Container, Grid, Box, ThemeProvider, createTheme} from '@mui/material';
import { getProducts } from '../services/product';
import { getCategories } from '../services/category';
import FiltersContent from './Filters';
import ProductDetail from './ProductDetail';
import PromoBanner from './PromoBanner';
import { useCart } from '../context/CartContext';
import Breadcrumbs from './Breadcrumbs';
import 'swiper/css/bundle';
import ProductCard from './ProductCard';
import ScrollToTop from './ScrollToTop';

const theme = createTheme({
    palette: {
        background: {
            default: '#f8f4f1', // Soft cream
        },
        text: {
            primary: '#5a4a42', // Warm brown
            secondary: '#b0a199', // Muted pink-brown
        },
        primary: {
            main: '#d7c0ae', // Soft beige
        },
    },
    typography: {
        fontFamily: 'Georgia, serif',
    },
});

function Products() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const {isInCart } = useCart();
    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
    const [priceRange, setPriceRange] = useState([0, 1000]);

    const handleCloseDetail = () => {
        setSelectedProduct(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [fetchedProducts, fetchedCategories] = await Promise.all([
                    getProducts(),
                    getCategories()
                ]);
                setProducts(fetchedProducts);
                setCategories(fetchedCategories);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const handleCategoryChange = (newCategories) => {
        setSelectedCategories(Array.isArray(newCategories) ? newCategories : []);
    };

    const filteredProducts = useMemo(() => {
        return products.filter(product => {
            const matchesSearch = product.nombre.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategories.length === 0 ||
                selectedCategories.includes(product.id_categoria);
            const matchesFavorites = !showOnlyFavorites || isInCart(product.id);
            const matchesPrice = product.precio >= priceRange[0] && 
                               product.precio <= priceRange[1];

            return matchesSearch && 
                   matchesCategory && 
                   matchesFavorites && 
                   matchesPrice;
        });
    }, [products, searchTerm, selectedCategories, showOnlyFavorites, isInCart, priceRange]);

    const breadcrumbItems = [
        {
            label: 'Productos',
            path: '/products',
            disabled: true
        }
    ];

    const handlePriceRangeChange = (newValue) => {
        setPriceRange(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Box sx={{ mb: 4 }}>
                    <PromoBanner />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mb: 4
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 1,
                        backgroundColor: '#fafafa',
                        width: '100%',
                        borderRadius: '10px'
                    }}>
                        <Breadcrumbs items={breadcrumbItems} />
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <FiltersContent
                            searchTerm={searchTerm}
                            onSearchChange={(value) => setSearchTerm(value)}
                            categories={categories}
                            selectedCategories={selectedCategories}
                            onCategoryChange={handleCategoryChange}
                            showOnlyFavorites={showOnlyFavorites}
                            onFavoritesChange={setShowOnlyFavorites}
                            priceRange={priceRange}
                            onPriceRangeChange={handlePriceRangeChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid 
                                container 
                                spacing={2}
                                sx={{
                                    justifyContent: filteredProducts.length < 3 ? 'flex-start' : 'flex-start',
                                    '& .MuiGrid-item': {
                                        width: {
                                            xs: '50%',  // 2 columnas en móvil
                                            md: filteredProducts.length === 1 ? '33.33%' : 
                                                filteredProducts.length === 2 ? '33.33%' :
                                                '33.33%'  // 3 columnas en desktop
                                        },
                                        maxWidth: {
                                            xs: '50%',
                                            md: '33.33%'
                                        }
                                    }
                                }}
                            >
                                {filteredProducts.map((product) => (
                                    <Grid 
                                        item 
                                        key={product.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'stretch',
                                            padding: 1
                                        }}
                                    >
                                        <ProductCard
                                            product={product}
                                            sx={{ 
                                                width: '100%',
                                                height: '100%',
                                                maxWidth: '300px',
                                                margin: '0 auto'
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <ScrollToTop />
            </Container>
            <ProductDetail
                open={!!selectedProduct}
                onClose={handleCloseDetail}
                product={selectedProduct}
                categoryName={selectedProduct ? categories.find(cat => cat.id === selectedProduct.id_categoria)?.nombre : ''}
            />
        </ThemeProvider>
    );
}

export default Products;