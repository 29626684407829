import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';

function PrivacyPolicy() {
    return (
        <Container maxWidth="md" sx={{ py: 6 }}>
            <Typography variant="h4" color="#5a4a42" gutterBottom sx={{ mb: 4 }}>
                Política de Privacidad
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Section
                    title="1. Nuestro Compromiso"
                    content={`EP Boutique es un catálogo online que respeta tu privacidad:
                    • No recopilamos datos personales
                    • No utilizamos cookies de seguimiento
                    • No almacenamos información de los visitantes
                    
                    Nuestro sitio web funciona como un catálogo digital puramente informativo.`}
                />

                <Section
                    title="2. Funcionamiento del Sitio"
                    content={`Nuestro catálogo:
                    • Muestra productos y sus detalles
                    • Permite la visualización de imágenes
                    • No requiere registro
                    • No almacena preferencias de usuario`}
                />

                <Section
                    title="3. Enlaces Externos"
                    content={`Nuestro sitio incluye enlaces a:
                    • Nuestras redes sociales
                    • Canales de contacto
                    
                    Al hacer clic en estos enlaces, estarás sujeto a las políticas de privacidad de dichas plataformas.`}
                />
            </Box>

            <Typography 
                variant="caption" 
                color="#b0a199" 
                sx={{ 
                    display: 'block',
                    mt: 4,
                    textAlign: 'center'
                }}
            >
                Última actualización: {new Date().toLocaleDateString()}
            </Typography>
        </Container>
    );
}

// Componente auxiliar para las secciones
function Section({ title, content }) {
    return (
        <Box>
            <Typography 
                variant="h6" 
                color="#5a4a42" 
                gutterBottom 
                sx={{ 
                    fontWeight: 500,
                    mb: 2
                }}
            >
                {title}
            </Typography>
            <Typography 
                variant="body1" 
                color="#5a4a42" 
                sx={{ 
                    whiteSpace: 'pre-line',
                    mb: 2,
                    opacity: 0.9
                }}
            >
                {content}
            </Typography>
            <Divider sx={{ mt: 3 }} />
        </Box>
    );
}

export default PrivacyPolicy; 