import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    ImageList,
    ImageListItem,
    Box,
    Typography,
    Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { deleteProductImage, updateProductImageMain } from '../../services/product';

export default function ProductImagesDialog({ open, onClose, product, onImageDelete }) {
    const [localImages, setLocalImages] = useState([]);

    useEffect(() => {
        if (product && product.imagenes) {
            setLocalImages(product.imagenes);
        }
    }, [product]);

    const handleDeleteImage = async (imageId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta imagen?')) {
            try {
                await deleteProductImage(product.id, imageId);
                setLocalImages(prevImages => 
                    prevImages.filter(img => img.id !== imageId)
                );
                onImageDelete();
            } catch (error) {
                console.error('Error al eliminar imagen:', error);
            }
        }
    };

    const handleSetMainImage = async (imageId) => {
        try {
            await updateProductImageMain(product.id, imageId);
            setLocalImages(prevImages => 
                prevImages.map(img => ({
                    ...img,
                    is_main: img.id === imageId ? 1 : 0
                }))
            );
            onImageDelete(); // Para actualizar la vista principal
        } catch (error) {
            console.error('Error al establecer imagen principal:', error);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ 
                color: '#5a4a42',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                Imágenes del Producto
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {localImages.length > 0 ? (
                    <ImageList sx={{ width: '100%' }} cols={3} rowHeight={200}>
                        {localImages.map((image) => (
                            <ImageListItem 
                                key={`image-${image.id}`}
                                sx={{
                                    position: 'relative',
                                    '&:hover .image-controls': {
                                        opacity: 1
                                    }
                                }}
                            >
                                <img
                                    src={image.url}
                                    alt="Imagen del producto"
                                    loading="lazy"
                                    style={{ 
                                        borderRadius: '8px',
                                        height: '200px',
                                        objectFit: 'cover',
                                        filter: parseInt(image.is_main) === 1 ? 'brightness(1)' : 'brightness(0.9)'
                                    }}
                                />
                                <Box 
                                    className="image-controls"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        padding: 1,
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        background: 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 30%, transparent 70%, rgba(0,0,0,0.4) 100%)'
                                    }}
                                >
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Tooltip title={
                                            parseInt(image.is_main) === 1 
                                                ? "Imagen principal actual" 
                                                : "Establecer como imagen principal"
                                        }>
                                            <IconButton
                                                onClick={() => handleSetMainImage(image.id)}
                                                sx={{
                                                    color: parseInt(image.is_main) === 1 ? '#ffd700' : '#ffffff'
                                                }}
                                            >
                                                {parseInt(image.is_main) === 1 ? (
                                                    <StarIcon />
                                                ) : (
                                                    <StarBorderIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            onClick={() => handleDeleteImage(image.id)}
                                            sx={{
                                                color: '#fff',
                                                backgroundColor: 'rgba(229,115,115,0.8)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(229,115,115,1)'
                                                }
                                            }}
                                            size="small"
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    {parseInt(image.is_main) === 1 && (
                                        <Typography 
                                            sx={{ 
                                                color: '#fff',
                                                textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                                alignSelf: 'center',
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                padding: '2px 8px',
                                                borderRadius: '4px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            Imagen Principal
                                        </Typography>
                                    )}
                                </Box>
                            </ImageListItem>
                        ))}
                    </ImageList>
                ) : (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '200px'
                    }}>
                        <Typography sx={{ color: '#b0a199' }}>
                            No hay imágenes para este producto
                        </Typography>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
} 