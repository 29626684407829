import React, { useState } from 'react';
import { 
    Box,
    Fab,
    Zoom,
    Typography
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import ChatBot from './ChatBot';
import { useSettings } from '../context/SettingsContext';

const CommunicationMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const { settings } = useSettings();
    const whatsappNumber = settings?.find(s => s.key_name === 'whatsapp_number')?.value || '34600000000';

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (isOpen) setIsChatOpen(false);
    };

    return (
        <>
            {/* Botón principal */}
            <Fab 
                color="primary" 
                onClick={toggleMenu}
                sx={{ 
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    bgcolor: '#d7c0ae',
                    '&:hover': { bgcolor: '#c4ae9d' },
                    zIndex: 1000
                }}
            >
                {isOpen ? <CloseIcon /> : <MessageIcon />}
            </Fab>

            {/* Opciones de comunicación */}
            <Box sx={{ position: 'fixed', bottom: 90, right: 20, zIndex: 999 }}>
                <Zoom in={isOpen} style={{ transitionDelay: isOpen ? '100ms' : '0ms' }}>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        gap: 2
                    }}>
                        {/* Botón de WhatsApp */}
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: 1
                        }}>
                            <Typography
                                sx={{ 
                                    bgcolor: 'white',
                                    px: 2,
                                    py: 1,
                                    borderRadius: 2,
                                    boxShadow: 1,
                                    color: '#5a4a42'
                                }}
                            >
                                WhatsApp
                            </Typography>
                            <Fab 
                                size="small"
                                onClick={handleWhatsAppClick}
                                sx={{ 
                                    bgcolor: '#25D366',
                                    '&:hover': { bgcolor: '#1fab54' }
                                }}
                            >
                                <WhatsAppIcon />
                            </Fab>
                        </Box>

                        {/* Botón de Chat */}
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: 1
                        }}>
                            <Typography
                                sx={{ 
                                    bgcolor: 'white',
                                    px: 2,
                                    py: 1,
                                    borderRadius: 2,
                                    boxShadow: 1,
                                    color: '#5a4a42'
                                }}
                            >
                                Chat
                            </Typography>
                            <Fab 
                                size="small"
                                onClick={() => setIsChatOpen(!isChatOpen)}
                                sx={{ 
                                    bgcolor: '#d7c0ae',
                                    '&:hover': { bgcolor: '#c4ae9d' }
                                }}
                            >
                                <ChatIcon />
                            </Fab>
                        </Box>
                    </Box>
                </Zoom>
            </Box>

            {/* Componente ChatBot */}
            <Zoom in={isChatOpen && isOpen}>
                <Box sx={{ 
                    position: 'fixed',
                    bottom: 200,
                    right: 20,
                    zIndex: 998
                }}>
                    {isChatOpen && <ChatBot onClose={() => setIsChatOpen(false)} />}
                </Box>
            </Zoom>
        </>
    );
};

export default CommunicationMenu; 