import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    IconButton,
    Typography,
    Paper,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
    Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { getSettings, updateSetting, createSetting, deleteSetting } from '../../services/setting';

function SettingsPanel() {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [newSetting, setNewSetting] = useState({
        key_name: '',
        value: '',
        type: 'text',
        label: '',
        description: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        loadSettings();
    }, []);

    const loadSettings = async () => {
        try {
            const data = await getSettings();
            setSettings(data);
        } catch (error) {
            console.error('Error loading settings:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredSettings = settings.filter(setting =>
        setting.key_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        setting.value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleEdit = (setting) => {
        setCurrentSetting(setting);
        setEditValue(setting.value);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setCurrentSetting(null);
        setEditValue('');
    };

    const handleCloseAdd = () => {
        setOpenAddDialog(false);
        setNewSetting({
            key_name: '',
            value: '',
            type: 'text',
            label: '',
            description: ''
        });
    };

    const handleSave = async () => {
        try {
            const updatedSetting = {
                ...currentSetting,
                value: editValue
            };
            
            await updateSetting(currentSetting.id, updatedSetting);
            setSettings(settings.map(setting => 
                setting.id === currentSetting.id
                    ? updatedSetting
                    : setting
            ));
            handleClose();
        } catch (error) {
            console.error('Error updating setting:', error);
        }
    };

    const handleAdd = async () => {
        try {
            const created = await createSetting(newSetting);
            setSettings([...settings, created]);
            handleCloseAdd();
        } catch (error) {
            console.error('Error creating setting:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta configuración?')) {
            try {
                await deleteSetting(id);
                setSettings(settings.filter(setting => setting.id !== id));
            } catch (error) {
                console.error('Error deleting setting:', error);
            }
        }
    };

    const handleCopyKey = (key_name) => {
        navigator.clipboard.writeText(key_name);
        setSnackbar({
            open: true,
            message: 'Clave copiada al portapapeles',
            severity: 'success'
        });
    };

    if (loading) return <Typography>Cargando...</Typography>;

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
            }}>
                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                    Gestión de Configuraciones
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenAddDialog(true)}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        '&:hover': {
                            backgroundColor: '#c4ae9d'
                        }
                    }}
                >
                    Nueva Configuración
                </Button>
            </Box>

            {/* Buscador */}
            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar configuración..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#5a4a42' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: '#f8f4f1',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#d7c0ae',
                            },
                            '&:hover fieldset': {
                                borderColor: '#c4ae9d',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#5a4a42',
                            },
                        },
                    }}
                />
            </Box>

            <Grid container spacing={3}>
                {filteredSettings.map((setting) => (
                    <Grid item xs={12} sm={6} md={4} key={setting.id}>
                        <Paper
                            sx={{
                                p: 3,
                                borderRadius: 2,
                                boxShadow: '4px 4px 8px #e1ddd9, -4px -4px 8px #ffffff',
                                transition: 'transform 0.2s ease-in-out',
                                height: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                '&:hover': {
                                    transform: 'translateY(-3px)'
                                }
                            }}
                        >
                            <Box>
                                <Typography 
                                    variant="subtitle2" 
                                    sx={{ 
                                        color: '#b0a199',
                                        mb: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    {setting.key_name}
                                    <Tooltip title="Copiar clave">
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleCopyKey(setting.key_name)}
                                            sx={{ 
                                                color: '#b0a199',
                                                '&:hover': { 
                                                    color: '#5a4a42'
                                                }
                                            }}
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    sx={{ 
                                        color: '#5a4a42',
                                        fontWeight: 'medium',
                                        mb: 1,
                                        wordBreak: 'break-word',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 4,
                                        WebkitBoxOrient: 'vertical',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {setting.value}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'flex-end',
                                gap: 1,
                                mt: 'auto'
                            }}>
                                <IconButton 
                                    size="small"
                                    onClick={() => handleEdit(setting)}
                                    sx={{ 
                                        color: '#5a4a42',
                                        backgroundColor: 'rgba(215,192,174,0.2)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(215,192,174,0.4)'
                                        }
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    size="small"
                                    onClick={() => handleDelete(setting.id)}
                                    sx={{ 
                                        color: '#e57373',
                                        backgroundColor: 'rgba(229,115,115,0.1)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(229,115,115,0.2)'
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Diálogo para añadir nueva configuración */}
            <Dialog 
                open={openAddDialog} 
                onClose={handleCloseAdd}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{ color: '#5a4a42' }}>Nueva Configuración</DialogTitle>
                <DialogContent>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2, 
                        mt: 2
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Clave"
                                    fullWidth
                                    value={newSetting.key_name}
                                    onChange={(e) => setNewSetting({...newSetting, key_name: e.target.value})}
                                    placeholder="Ejemplo: whatsapp_number"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Tipo</InputLabel>
                                    <Select
                                        value={newSetting.type}
                                        label="Tipo"
                                        onChange={(e) => setNewSetting({...newSetting, type: e.target.value})}
                                        sx={{
                                            backgroundColor: '#ffffff'
                                        }}
                                    >
                                        <MenuItem value="text">Texto</MenuItem>
                                        <MenuItem value="textarea">Texto Largo</MenuItem>
                                        <MenuItem value="number">Número</MenuItem>
                                        <MenuItem value="url">URL</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Etiqueta"
                                    fullWidth
                                    value={newSetting.label}
                                    onChange={(e) => setNewSetting({...newSetting, label: e.target.value})}
                                    placeholder="Ejemplo: Número de WhatsApp"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Descripción"
                                    fullWidth
                                    value={newSetting.description}
                                    onChange={(e) => setNewSetting({...newSetting, description: e.target.value})}
                                    placeholder="Describe el propósito de esta configuración"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            label="Valor"
                            fullWidth
                            value={newSetting.value}
                            onChange={(e) => setNewSetting({...newSetting, value: e.target.value})}
                            multiline={newSetting.type === 'textarea'}
                            rows={newSetting.type === 'textarea' ? 8 : 1}
                            type={newSetting.type === 'number' ? 'number' : 'text'}
                            placeholder="Valor inicial de la configuración"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#ffffff'
                                },
                                mt: 2
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button 
                        onClick={handleCloseAdd}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleAdd}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Crear
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para editar configuración */}
            <Dialog 
                open={openDialog} 
                onClose={handleClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{ color: '#5a4a42', pb: 1 }}>
                    Editar Configuración
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 3,
                        pt: 2
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Clave"
                                    fullWidth
                                    size="medium"
                                    value={currentSetting?.key_name || ''}
                                    onChange={(e) => setCurrentSetting({
                                        ...currentSetting,
                                        key_name: e.target.value
                                    })}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="medium">
                                    <InputLabel>Tipo</InputLabel>
                                    <Select
                                        value={currentSetting?.type || 'text'}
                                        label="Tipo"
                                        onChange={(e) => setCurrentSetting({
                                            ...currentSetting,
                                            type: e.target.value
                                        })}
                                        sx={{
                                            backgroundColor: '#ffffff'
                                        }}
                                    >
                                        <MenuItem value="text">Texto</MenuItem>
                                        <MenuItem value="textarea">Texto Largo</MenuItem>
                                        <MenuItem value="number">Número</MenuItem>
                                        <MenuItem value="url">URL</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Etiqueta"
                                    fullWidth
                                    value={currentSetting?.label || ''}
                                    onChange={(e) => setCurrentSetting({
                                        ...currentSetting,
                                        label: e.target.value
                                    })}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Descripción"
                                    fullWidth
                                    value={currentSetting?.description || ''}
                                    onChange={(e) => setCurrentSetting({
                                        ...currentSetting,
                                        description: e.target.value
                                    })}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: '#ffffff'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            label="Valor"
                            fullWidth
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            multiline={currentSetting?.type === 'textarea'}
                            rows={currentSetting?.type === 'textarea' ? 8 : 1}
                            type={currentSetting?.type === 'number' ? 'number' : 'text'}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#ffffff'
                                },
                                mt: 2
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button 
                        onClick={handleClose}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleSave}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SettingsPanel; 