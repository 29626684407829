import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { getProductById } from '../services/product';
import { getCategories } from '../services/category';
import { getProducts } from '../services/product';
import ProductDetail from '../components/ProductDetail';

function ProductDetailPage() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [categories, setCategories] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productData = await getProductById(id);
                setProduct(productData);

                const [categoriesData, allProducts] = await Promise.all([
                    getCategories(),
                    getProducts()
                ]);
                
                setCategories(categoriesData);

                const related = allProducts
                    .filter(p => 
                        p.id_categoria === productData.id_categoria && 
                        p.id !== productData.id
                    )
                    .slice(0, 6);

                setRelatedProducts(related);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [id]);

    const categoryName = product ? 
        categories.find(cat => cat.id === product.id_categoria)?.nombre : '';

    return (
        <>
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <ProductDetail 
                    open={true} 
                    product={product}
                    categoryName={categoryName}
                    relatedProducts={relatedProducts}
                />
            </Container>
        </>
    );
}

export default ProductDetailPage; 