import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getPromos } from '../services/promos';

const PromoContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#d7c0ae',
    padding: '24px',
    textAlign: 'center',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const PromoText = styled(Typography)({
    color: '#5a4a42',
    fontFamily: 'Georgia, serif',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    '& strong': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    '& a': {
        color: '#5a4a42',
        textDecoration: 'underline',
        '&:hover': {
            color: '#fff'
        }
    }
});

function PromoBanner() {
    const [promos, setPromos] = useState([]);
    
    useEffect(() => {
        const fetchPromos = async () => {
            try {
                const data = await getPromos();
                const activePromos = data
                    .filter(promo => promo.activo)
                    .sort((a, b) => (a.orden || 0) - (b.orden || 0));
                setPromos(activePromos);
            } catch (error) {
                console.error('Error fetching promos:', error);
            }
        };
        
        fetchPromos();
    }, []);

    const settings = {
        dots: promos.length > 1,
        infinite: promos.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: promos.length > 1,
        autoplaySpeed: 4000,
        arrows: false,
        pauseOnHover: true
    };

    if (promos.length === 0) return null;

    if (promos.length === 1) {
        return (
            <PromoContainer>
                <PromoText>
                    <span dangerouslySetInnerHTML={{ __html: promos[0].texto }} />
                    {promos[0].subtexto && (
                        <Box 
                            component="span" 
                            sx={{ 
                                fontSize: '0.9rem', 
                                mt: 0.5,
                                display: 'block'
                            }}
                            dangerouslySetInnerHTML={{ __html: promos[0].subtexto }}
                        />
                    )}
                </PromoText>
            </PromoContainer>
        );
    }

    return (
        <Box sx={{ 
            '& .slick-dots': {
                bottom: '-30px',
                '& li': {
                    margin: 0,
                    '& button:before': {
                        color: '#d7c0ae',
                        opacity: 0.5,
                        fontSize: '8px'
                    },
                    '&.slick-active button:before': {
                        color: '#5a4a42',
                        opacity: 1
                    }
                }
            },
            '& .slick-slider': {
                marginBottom: '30px'
            }
        }}>
            <Slider {...settings}>
                {promos.map((promo) => (
                    <div key={promo.id}>
                        <PromoContainer>
                            <PromoText>
                                <span dangerouslySetInnerHTML={{ __html: promo.texto }} />
                                {promo.subtexto && (
                                    <Box 
                                        component="span" 
                                        sx={{ 
                                            fontSize: '0.9rem', 
                                            mt: 0.5,
                                            display: 'block'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: promo.subtexto }}
                                    />
                                )}
                            </PromoText>
                        </PromoContainer>
                    </div>
                ))}
            </Slider>
        </Box>
    );
}

export default PromoBanner;