import React, { useState, useEffect } from 'react';
import { Fab, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    // Controlar la visibilidad del botón
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) { // Mostrar después de 300px de scroll
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Función para hacer scroll hacia arriba
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <Zoom in={isVisible}>
            <Fab
                onClick={scrollToTop}
                size="small"
                aria-label="scroll back to top"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: 16,
                    bgcolor: '#d7c0ae',
                    color: '#5a4a42',
                    '&:hover': {
                        bgcolor: '#b0a199',
                    },
                    zIndex: 1000,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

export default ScrollToTop; 