export const getPromos = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}promos`);
    const data = await response.json();
    return data;
}; 

export const createPromo = async (promo) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}promos`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(promo)
    });
    return response.json();
};

export const updatePromo = async (id,promo) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}promos/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(promo)
    });
    return response.json();
};

export const deletePromo = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}promos/${id}`, {
        method: 'DELETE'
    });
    return response.json();
};

export const getPromoById = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}promos/${id}`);
    return response.json();
};

