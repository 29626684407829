import React, { createContext, useState, useContext, useEffect } from 'react';
import { getSettings } from '../services/setting';

const SettingsContext = createContext({
    settings: [],
    loading: true,
    refreshSettings: () => {}
});

export function SettingsProvider({ children }) {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadSettings = async () => {
        try {
            const data = await getSettings();
            setSettings(data);
        } catch (error) {
            console.error('Error loading settings:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);

    const value = {
        settings,
        loading,
        refreshSettings: loadSettings
    };

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}

export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
}; 