import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    IconButton,
    Collapse,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableFooter,
    Grid
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { getOrders, updateOrderStatus, deleteOrder } from '../../services/orders';
import { useSettings } from '../../context/SettingsContext';
export default function OrdersPanel() {
    const { settings } = useSettings();
    const [orders, setOrders] = useState([]);
    const [openRows, setOpenRows] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await getOrders();
            console.log(response);
            setOrders(response);
        } catch (error) {
            console.error('Error al cargar los pedidos:', error);
        }
    };

    const handleStatusChange = async (orderId, newStatus) => {
        try {
            await updateOrderStatus(orderId, newStatus);
            fetchOrders(); // Recargar los pedidos
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
        }
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            await deleteOrder(orderId); // Necesitarás crear esta función en tu servicio
            fetchOrders(); // Recargar los pedidos
            setOpenDeleteDialog(false);
            setOrderToDelete(null);
        } catch (error) {
            console.error('Error al eliminar el pedido:', error);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pendiente':
                return {
                    color: '#ff9800',
                    backgroundColor: 'rgba(255, 152, 0, 0.1)',
                    borderColor: '#ff9800'
                };
            case 'contactado':
                return {
                    color: '#2196f3',
                    backgroundColor: 'rgba(33, 150, 243, 0.1)',
                    borderColor: '#2196f3'
                };
            case 'completado':
                return {
                    color: '#4caf50',
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    borderColor: '#4caf50'
                };
            case 'cancelado':
                return {
                    color: '#f44336',
                    backgroundColor: 'rgba(244, 67, 54, 0.1)',
                    borderColor: '#f44336'
                };
            default:
                return {
                    color: '#757575',
                    backgroundColor: 'rgba(117, 117, 117, 0.1)',
                    borderColor: '#757575'
                };
        }
    };

    const getStatusLabel = (status) => {
        const labels = {
            pendiente: 'Pendiente',
            contactado: 'Contactado',
            completado: 'Completado',
            cancelado: 'Cancelado'
        };
        return labels[status] || status;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <Box>
            <Typography variant="h5" sx={{ mb: 3, color: '#5a4a42' }}>
                Gestión de Pedidos
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f8f4f1' }}>
                            <TableCell />
                            <TableCell>Fecha</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Contacto</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <React.Fragment key={order.id}>
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => setOpenRows({
                                                ...openRows,
                                                [order.id]: !openRows[order.id]
                                            })}
                                        >
                                            {openRows[order.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{formatDate(order.fecha_creacion)}</TableCell>
                                    <TableCell>{order.nombre}</TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <IconButton
                                                size="small"
                                                href={`https://wa.me/${order.telefono}`}
                                                target="_blank"
                                                sx={{ color: '#25D366' }}
                                            >
                                                <WhatsAppIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                href={`mailto:${order.email}`}
                                                sx={{ color: '#EA4335' }}
                                            >
                                                <EmailIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={getStatusLabel(order.estado)}
                                            sx={{
                                                ...getStatusColor(order.estado),
                                                fontWeight: 500,
                                                '&:hover': {
                                                    backgroundColor: (theme) => `${getStatusColor(order.estado).backgroundColor} !important`
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => setSelectedOrder(order)}
                                                sx={{ 
                                                    color: '#5a4a42',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(90, 74, 66, 0.1)'
                                                    }
                                                }}
                                            >
                                                <AssignmentTurnedInIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => {
                                                    setOrderToDelete(order);
                                                    setOpenDeleteDialog(true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={openRows[order.id]} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 2 }}>
                                                <Box sx={{ 
                                                    mb: 3, 
                                                    p: 2, 
                                                    backgroundColor: '#f8f4f1',
                                                    borderRadius: 1
                                                }}>
                                                    <Typography variant="h6" gutterBottom sx={{ color: '#5a4a42' }}>
                                                        Información del Cliente
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Box sx={{ mb: 1 }}>
                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                    Nombre:
                                                                </Typography>
                                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                                    {order.nombre}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mb: 1 }}>
                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                    Email:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {order.email}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mb: 1 }}>
                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                    Teléfono:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {order.telefono}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Box>
                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                    Dirección de envío:
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {order.direccion}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<WhatsAppIcon />}
                                                                    href={`https://wa.me/${order.telefono}`}
                                                                    target="_blank"
                                                                    sx={{ 
                                                                        color: '#25D366',
                                                                        borderColor: '#25D366',
                                                                        '&:hover': {
                                                                            borderColor: '#25D366',
                                                                            backgroundColor: 'rgba(37, 211, 102, 0.1)'
                                                                        }
                                                                    }}
                                                                >
                                                                    WhatsApp
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<EmailIcon />}
                                                                    href={`mailto:${order.email}`}
                                                                    sx={{ 
                                                                        color: '#EA4335',
                                                                        borderColor: '#EA4335',
                                                                        '&:hover': {
                                                                            borderColor: '#EA4335',
                                                                            backgroundColor: 'rgba(234, 67, 53, 0.1)'
                                                                        }
                                                                    }}
                                                                >
                                                                    Email
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Typography variant="h6" gutterBottom sx={{ color: '#5a4a42' }}>
                                                    Productos del pedido
                                                </Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Producto</TableCell>
                                                            <TableCell>Imagen</TableCell>
                                                            <TableCell>Cantidad</TableCell>
                                                            <TableCell>Precio</TableCell>
                                                            <TableCell>Total</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(order.productos).map((producto, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{producto.nombre}</TableCell>
                                                                <TableCell>
                                                                    {producto.imagen && (
                                                                        <Box
                                                                            component="img"
                                                                            src={producto.imagen}
                                                                            alt={producto.nombre}
                                                                            sx={{
                                                                                width: 60,
                                                                                height: 60,
                                                                                objectFit: 'cover',
                                                                                borderRadius: 1,
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    transform: 'scale(1.05)',
                                                                                    transition: 'transform 0.2s'
                                                                                }
                                                                            }}
                                                                            onClick={() => {
                                                                                window.open(producto.imagen, '_blank');
                                                                            }}
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{producto.cantidad}</TableCell>
                                                                <TableCell>
                                                                    {settings.find(setting => setting.key_name === 'moneda')?.value} {producto.precio_oferta || producto.precio}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {settings.find(setting => setting.key_name === 'moneda')?.value} {(producto.precio_oferta || producto.precio) * producto.cantidad}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell colSpan={4} align="right">
                                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                    Total del pedido:
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                    {settings.find(setting => setting.key_name === 'moneda')?.value} {(order.productos).reduce((total, producto) => 
                                                                        total + ((producto.precio_oferta || producto.precio) * producto.cantidad), 0
                                                                    ).toFixed(2)}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={!!selectedOrder} onClose={() => setSelectedOrder(null)}>
                <DialogTitle sx={{ color: '#5a4a42' }}>
                    Actualizar Estado del Pedido
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2, 
                        mt: 2,
                        minWidth: '300px'
                    }}>
                        {['pendiente', 'contactado', 'completado', 'cancelado'].map((status) => (
                            <Button
                                key={status}
                                variant={selectedOrder?.estado === status ? "contained" : "outlined"}
                                onClick={() => {
                                    handleStatusChange(selectedOrder?.id, status);
                                    setSelectedOrder(null);
                                }}
                                sx={{
                                    ...getStatusColor(status),
                                    '&:hover': {
                                        backgroundColor: `${getStatusColor(status).backgroundColor} !important`,
                                        borderColor: `${getStatusColor(status).borderColor} !important`,
                                    }
                                }}
                            >
                                {getStatusLabel(status)}
                            </Button>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setSelectedOrder(null)}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={openDeleteDialog} 
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setOrderToDelete(null);
                }}
            >
                <DialogTitle sx={{ color: '#5a4a42' }}>
                    Confirmar Eliminación
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro que deseas eliminar este pedido? Esta acción no se puede deshacer.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenDeleteDialog(false);
                            setOrderToDelete(null);
                        }}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => handleDeleteOrder(orderToDelete?.id)}
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
} 