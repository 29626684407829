import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Chip,
    OutlinedInput,
    FormControlLabel,
    Switch,
    Rating as MuiRating,
    Typography
} from '@mui/material';
import { createProduct, updateProduct } from '../../services/product';
import StarIcon from '@mui/icons-material/Star';

export default function ProductFormDialog({ open, onClose, product, categories, colors, sizes}) {
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        precio: '',
        precio_oferta: '',
        rating: 0,
        id_categoria: '',
        is_new: false,
        in_stock: true,
        visible: 1,
        id_collection: null,
        colores: [],
        tallas: [],
        enable_sizes: false,
        enable_colors: false
    });

    useEffect(() => {
        if (product) {
            const productColorsIds = product.colores_id || product.colors_id || [];
            const productSizesIds = product.tallas_id || product.sizes_id || [];


            setFormData({
                nombre: product.nombre || '',
                descripcion: product.descripcion || '',
                precio: product.precio || '',
                precio_oferta: product.precio_oferta || '',
                rating: Number(product.rating) || 0,
                id_categoria: product.id_categoria || '',
                is_new: Boolean(product.is_new),
                in_stock: Boolean(product.in_stock),
                visible: Boolean(product.visible),
                id_collection: product.id_collection || null,
                colores: productColorsIds,
                tallas: productSizesIds,
                enable_sizes: Boolean(product.enable_sizes),
                enable_colors: Boolean(product.enable_colors)
            });
        } else {
            setFormData({
                nombre: '',
                descripcion: '',
                precio: '',
                precio_oferta: '',
                rating: 0,
                id_categoria: '',
                is_new: false,
                in_stock: true,
                visible: 1,
                id_collection: null,
                colores: [],
                tallas: [],
                enable_sizes: false,
                enable_colors: false
            });
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        if (type === 'checkbox') {
            setFormData(prev => ({
                ...prev,
                [name]: checked ? 1 : 0
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleMultipleSelect = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                is_new: formData.is_new ? 1 : 0,
                in_stock: formData.in_stock ? 1 : 0,
                visible: formData.visible ? 1 : 0,
                colores: formData.enable_colors ? formData.colores : [],
                tallas: formData.enable_sizes ? formData.tallas : []
            };

            if (product) {
                await updateProduct(product.id, dataToSend);
            } else {
                await createProduct(dataToSend);
            }
            onClose();
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    const labels = {
        0: 'Sin calificación',
        1: 'Malo',
        2: 'Regular',
        3: 'Bueno',
        4: 'Muy bueno',
        5: 'Excelente'
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="sm" 
            fullWidth
            keepMounted
            disablePortal
        >
            <DialogTitle sx={{ color: '#5a4a42' }}>
                {product ? 'Editar Producto' : 'Nuevo Producto'}
            </DialogTitle>
            <DialogContent>
                <Box 
                    component="form" 
                    sx={{ mt: 2 }} 
                    onSubmit={handleSubmit}
                    tabIndex={-1}
                >
                    <TextField
                        fullWidth
                        label="Nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Descripción"
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={3}
                    />
                    <TextField
                        fullWidth
                        label="Precio"
                        name="precio"
                        type="number"
                        value={formData.precio}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Precio Oferta"
                        name="precio_oferta"
                        type="number"
                        value={formData.precio_oferta}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Categoría</InputLabel>
                        <Select
                            name="id_categoria"
                            value={formData.id_categoria}
                            onChange={handleChange}
                            required
                        >
                            {categories.map(category => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2,
                        my: 2 
                    }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.enable_colors}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        enable_colors: e.target.checked,
                                        colores: e.target.checked ? prev.colores : []
                                    }))}
                                    name="enable_colors"
                                />
                            }
                            label="Habilitar colores"
                            sx={{ color: '#5a4a42' }}
                        />

                        {formData.enable_colors && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Colores</InputLabel>
                                <Select
                                    multiple
                                    name="colores"
                                    value={formData.colores}
                                    onChange={handleMultipleSelect}
                                    input={<OutlinedInput label="Colores" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((colorId) => {
                                                const color = colors.find(c => c.id === colorId);
                                                return color ? (
                                                    <Chip
                                                        key={colorId}
                                                        label={color.color}
                                                        sx={{
                                                            backgroundColor: color.color,
                                                            color: '#ffffff',
                                                            textShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                                        }}
                                                    />
                                                ) : null;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {colors.map(color => (
                                        <MenuItem key={color.id} value={color.id}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: '50%',
                                                        backgroundColor: color.color,
                                                        border: '1px solid rgba(0,0,0,0.1)'
                                                    }}
                                                />
                                                <span>{color.nombre}</span>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.enable_sizes}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        enable_sizes: e.target.checked,
                                        tallas: e.target.checked ? prev.tallas : []
                                    }))}
                                    name="enable_sizes"
                                />
                            }
                            label="Habilitar tallas"
                            sx={{ color: '#5a4a42' }}
                        />

                        {formData.enable_sizes && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="tallas-label">Tallas</InputLabel>
                                <Select
                                    labelId="tallas-label"
                                    multiple
                                    name="tallas"
                                    value={formData.tallas}
                                    onChange={handleMultipleSelect}
                                    input={<OutlinedInput label="Tallas" />}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5,
                                            },
                                        },
                                        disablePortal: true,
                                        keepMounted: true
                                    }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((sizeId) => {
                                                const size = sizes.find(s => s.id === sizeId);
                                                return size ? (
                                                    <Chip
                                                        key={sizeId}
                                                        label={size.talla}
                                                        sx={{
                                                            backgroundColor: '#d7c0ae',
                                                            color: '#ffffff'
                                                        }}
                                                    />
                                                ) : null;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {sizes.map(size => (
                                        <MenuItem key={size.id} value={size.id}>
                                            {size.talla}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            my: 2
                        }}
                    >
                        <Typography component="legend" sx={{ color: '#5a4a42' }}>
                            Calificación
                        </Typography>
                        <MuiRating
                            name="rating"
                            value={Number(formData.rating) || 0}
                            onChange={(event, newValue) => {
                                setFormData(prev => ({
                                    ...prev,
                                    rating: newValue
                                }));
                            }}
                            precision={1}
                            icon={<StarIcon fontSize="inherit" />}
                            emptyIcon={<StarIcon fontSize="inherit" />}
                            sx={{
                                '& .MuiRating-iconFilled': {
                                    color: '#ffd700',
                                },
                                '& .MuiRating-iconEmpty': {
                                    color: '#b0a199',
                                }
                            }}
                        />
                        <Typography sx={{ ml: 1, color: '#b0a199' }}>
                            {labels[Number(formData.rating) || 0]}
                        </Typography>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 1,
                        my: 2 
                    }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Boolean(formData.visible)}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        visible: e.target.checked ? 1 : 0
                                    }))}
                                    name="visible"
                                />
                            }
                            label="Visible al público"
                            sx={{ color: '#5a4a42' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Boolean(formData.is_new)}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        is_new: e.target.checked ? 1 : 0
                                    }))}
                                    name="is_new"
                                />
                            }
                            label="Producto nuevo"
                            sx={{ color: '#5a4a42' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Boolean(formData.in_stock)}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        in_stock: e.target.checked ? 1 : 0
                                    }))}
                                    name="in_stock"
                                />
                            }
                            label="En stock"
                            sx={{ color: '#5a4a42' }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose}
                    sx={{ color: '#5a4a42' }}
                    tabIndex={0}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={handleSubmit}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#c4ae9d'
                        }
                    }}
                    tabIndex={0}
                >
                    {product ? 'Guardar' : 'Crear'}
                </Button>
            </DialogActions>
        </Dialog>
    );
} 