export const getSizes = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}sizes`);
    const data = await response.json();
    return data;
};

export const createSize = async (size) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}sizes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(size)
    });
    return response.json();
}

export const updateSize = async (id, size) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}sizes/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(size)
    });
    return response.json();
}

export const deleteSize = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}sizes/${id}`, {
        method: 'DELETE'
    });
    return response.json();
}
