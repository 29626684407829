import React from 'react';
import { Box, Container, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Breadcrumbs from './Breadcrumbs';

const steps = [
    {
        icon: <FavoriteIcon sx={{ color: '#e57373' }} />,
        title: "Agregar a Favoritos",
        description: "Marca los productos que te gusten con el corazón para guardarlos en tu lista"
    },
    {
        icon: <ShoppingCartIcon sx={{ color: '#d7c0ae' }} />,
        title: "Revisar Lista",
        description: "Revisa tu lista de favoritos y selecciona los productos que deseas comprar"
    },
    {
        icon: <WhatsAppIcon sx={{ color: '#25D366' }} />,
        title: "Contactar por WhatsApp",
        description: "Comparte tu lista por WhatsApp para finalizar tu pedido"
    },
    {
        icon: <PaymentIcon sx={{ color: '#5a4a42' }} />,
        title: "Realizar el Pago",
        description: "Coordina el método de pago según las opciones disponibles"
    },
    {
        icon: <LocalShippingIcon sx={{ color: '#b0a199' }} />,
        title: "Envío",
        description: "Coordina la entrega o el envío de tus productos"
    }
];

function HowToBuy() {
    const breadcrumbItems = [
        {
            label: 'Productos',
            path: '/products',
            disabled: false
        },
        {
            label: '¿Cómo Comprar?',
            path: '/how-to-buy',
            disabled: true
        }
    ];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: '#fafafa',
                    mt: 2
                }}
            >
                <Container maxWidth="md">
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 1
                    }}>
                        <Breadcrumbs items={breadcrumbItems} />
                    </Box>
                </Container>
            </Box>
            <Container maxWidth="md" sx={{ py: 8 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 4,
                    mb: 6
                }}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#5a4a42',
                            textAlign: 'center',
                            fontFamily: 'Georgia, serif',
                            position: 'relative',
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-8px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '60px',
                                height: '2px',
                                backgroundColor: '#ff0000'
                            }
                        }}
                    >
                        ¿Cómo Comprar?
                    </Typography>
                </Box>

                <Box sx={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    overflow: 'hidden'
                }}>
                    <List>
                        {steps.map((step, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    py: 3,
                                    borderBottom: index < steps.length - 1 ? '1px solid rgba(0,0,0,0.1)' : 'none'
                                }}
                            >
                                <ListItemIcon>
                                    {step.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography sx={{
                                            color: '#5a4a42',
                                            fontWeight: 'medium',
                                            mb: 0.5
                                        }}>
                                            {step.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography sx={{
                                            color: '#b0a199',
                                            fontSize: '0.9rem'
                                        }}>
                                            {step.description}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Container>
        </>
    );
}

export default HowToBuy; 