export const getSettings = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}settings`);
    const data = await response.json();
    return data;
};

export const updateSetting = async (id, setting) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}settings/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(setting)
    });
    const data = await response.json();
    return data;
};

export const getSetting = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}settings/${id}`);
    const data = await response.json();
    return data;
};

export const deleteSetting = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}settings/${id}`, {
        method: 'DELETE'
    });
    const data = await response.json();
    return data;
};

export const createSetting = async (setting) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}settings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(setting)
    });
    const data = await response.json();
    return data;
};
