import './App.css';
import Rutas from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { CartProvider } from './context/CartContext';
import { SettingsProvider } from './context/SettingsContext';
import CommunicationMenu from './components/CommunicationMenu';
import CookiesBanner from './components/CookiesBanner';
const theme = createTheme(); 

function App() {

  return (
    <CartProvider>
      <SettingsProvider>
      <ThemeProvider theme={theme}>
        <Rutas />
        <CommunicationMenu />
        <CookiesBanner />
      </ThemeProvider>
      </SettingsProvider>
    </CartProvider>
  );
}

export default App;