import React from 'react';
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ProductCard from './ProductCard';
import 'swiper/css';
import 'swiper/css/navigation';

function RelatedProducts({ products }) {
    if (!products || products.length === 0) return null;

    return (
        <Box sx={{ 
            mt: 6,
            backgroundColor: '#f8f4f1',
            borderRadius: '20px',
            padding: '24px',
            boxShadow: `
                8px 8px 20px #d4d0cc,
                -8px -8px 20px #fcf8f5
            `,
            position: 'relative'
        }}>
            <Typography 
                variant="h5" 
                sx={{ 
                    mb: 4,
                    color: '#5a4a42',
                    textAlign: 'center',
                    fontWeight: 500
                }}
            >
                Productos Relacionados
            </Typography>
            
            <Swiper
                modules={[Navigation]}
                navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                }}
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                    },
                    968: {
                        slidesPerView: 3,
                    }
                }}
                style={{
                    paddingLeft: '40px',
                    paddingRight: '40px'
                }}
            >
                {products.map((product) => (
                    <SwiperSlide key={product.id}>
                        <ProductCard 
                            product={product}
                            sx={{ 
                                height: '100%',
                                backgroundColor: '#fff' 
                            }}
                        />
                    </SwiperSlide>
                ))}
                
                <div className="swiper-button-prev" style={{
                    color: '#5a4a42',
                    left: '-10px',
                    '&:after': {
                        fontSize: '24px'
                    }
                }}></div>
                <div className="swiper-button-next" style={{
                    color: '#5a4a42',
                    right: '-10px',
                    '&:after': {
                        fontSize: '24px'
                    }
                }}></div>
            </Swiper>

            <style>
                {`
                    .swiper-button-prev,
                    .swiper-button-next {
                        background-color: #f8f4f1;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        box-shadow: 4px 4px 10px #d4d0cc,
                                  -4px -4px 10px #fcf8f5;
                    }

                    .swiper-button-prev:after,
                    .swiper-button-next:after {
                        font-size: 20px;
                        color: #5a4a42;
                    }

                    .swiper-button-prev.swiper-button-disabled,
                    .swiper-button-next.swiper-button-disabled {
                        opacity: 0.35;
                    }
                `}
            </style>
        </Box>
    );
}

export default RelatedProducts;
