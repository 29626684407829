import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    IconButton,
    Typography,
    Paper,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    FormControlLabel,
    Switch,
    Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { getPromos, createPromo, updatePromo, deletePromo } from '../../services/promos';
export default function PromotionsPanel() {
    const [promotions, setPromotions] = useState([]);
    const [newPromotion, setNewPromotion] = useState({ 
        texto: '', 
        subtexto: '', 
        activo: true 
    });
    const [editingPromotion, setEditingPromotion] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchPromotions = async () => {
        try {
            const data = await getPromos();
            setPromotions(data);
        } catch (error) {
            console.error('Error al cargar promociones:', error);
            setPromotions([]);
        }
    };

    useEffect(() => {
        fetchPromotions();
    }, []);



    const filteredPromotions = promotions.filter(promotion => {
        if (!promotion || !promotion.texto) return false;
        return promotion.texto.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleAddPromotion = async () => {
        if (newPromotion.texto.trim()) {
            try {
                // TODO: Implementar createPromotion en el servicio
                await createPromo(newPromotion);
                setNewPromotion({ 
                    texto: '', 
                    subtexto: '', 
                    activo: true 
                });
                setOpenAddDialog(false);
                fetchPromotions();
            } catch (error) {
                console.error('Error al crear promoción:', error);
            }
        }
    };

    const handleEditSave = async () => {
        if (editingPromotion) {
            try {
                await updatePromo(editingPromotion.id, editingPromotion);
                setOpenDialog(false);
                fetchPromotions();
            } catch (error) {
                console.error('Error al actualizar promoción:', error);
            }
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta promoción?')) {
            try {
                await deletePromo(id);
                fetchPromotions();
            } catch (error) {
                console.error('Error al eliminar promoción:', error);
            }
        }
    };

    const handleToggleActive = async (promotion) => {
        try {
            const updatedPromotion = { ...promotion, activo: !promotion.activo };
            await updatePromo(promotion.id, updatedPromotion);
            fetchPromotions();
        } catch (error) {
            console.error('Error al actualizar el estado de la promoción:', error);
        }
    };

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
            }}>
                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                    Gestión de Promociones
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenAddDialog(true)}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        '&:hover': {
                            backgroundColor: '#c4ae9d'
                        }
                    }}
                >
                    Nueva Promoción
                </Button>
            </Box>

            {/* Buscador */}
            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar promoción..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#5a4a42' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: '#f8f4f1',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#d7c0ae',
                            },
                            '&:hover fieldset': {
                                borderColor: '#c4ae9d',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#5a4a42',
                            },
                        },
                    }}
                />
            </Box>

            {/* Lista de promociones */}
            <Grid container spacing={2}>
                {filteredPromotions.map((promotion) => (
                    <Grid item xs={12} sm={6} md={4} key={promotion.id}>
                        <Paper
                            sx={{
                                p: 2,
                                backgroundColor: '#f8f4f1',
                                borderRadius: 2,
                                boxShadow: '4px 4px 8px #e1ddd9, -4px -4px 8px #ffffff',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-3px)'
                                }
                            }}
                        >
                            <Box sx={{ mb: 2 }}>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: '#5a4a42',
                                        '& p': { margin: 0 },
                                        '& h1, & h2, & h3, & h4, & h5, & h6': { margin: 0 }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: promotion.texto }}
                                />
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        color: '#867970',
                                        mt: 1,
                                        '& p': { margin: 0 },
                                        '& h1, & h2, & h3, & h4, & h5, & h6': { margin: 0 }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: promotion.subtexto }}
                                />
                                <Box sx={{ mt: 1 }}>
                                    <Chip 
                                        label={promotion.activo ? "Activo" : "Inactivo"}
                                        color={promotion.activo ? "success" : "default"}
                                        size="small"
                                        onClick={() => handleToggleActive(promotion)}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: promotion.activo ? '#a5d6a7' : '#e0e0e0'
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'flex-end',
                                gap: 1
                            }}>
                                <IconButton 
                                    onClick={() => {
                                        setEditingPromotion(promotion);
                                        setOpenDialog(true);
                                    }}
                                    sx={{ 
                                        color: '#5a4a42',
                                        backgroundColor: 'rgba(215,192,174,0.2)',
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton 
                                    onClick={() => handleDelete(promotion.id)}
                                    sx={{ 
                                        color: '#e57373',
                                        backgroundColor: 'rgba(229,115,115,0.1)',
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Diálogo para añadir nueva promoción */}
            <Dialog 
                open={openAddDialog} 
                onClose={() => setOpenAddDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ color: '#5a4a42' }}>Nueva Promoción</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        label="Texto"
                        value={newPromotion.texto}
                        onChange={(e) => setNewPromotion({...newPromotion, texto: e.target.value})}
                        multiline
                        rows={4}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            },
                            mb: 2
                        }}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Subtexto"
                        value={newPromotion.subtexto}
                        onChange={(e) => setNewPromotion({...newPromotion, subtexto: e.target.value})}
                        multiline
                        rows={3}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            },
                            mb: 2
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newPromotion.activo}
                                onChange={(e) => setNewPromotion({...newPromotion, activo: e.target.checked})}
                                color="primary"
                            />
                        }
                        label="Activo"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenAddDialog(false)}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleAddPromotion}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para editar promoción */}
            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ color: '#5a4a42' }}>Editar Promoción</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        label="Texto"
                        value={editingPromotion?.texto || ''}
                        onChange={(e) => setEditingPromotion({
                            ...editingPromotion,
                            texto: e.target.value
                        })}
                        multiline
                        rows={4}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            },
                            mb: 2
                        }}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Subtexto"
                        value={editingPromotion?.subtexto || ''}
                        onChange={(e) => setEditingPromotion({
                            ...editingPromotion,
                            subtexto: e.target.value
                        })}
                        multiline
                        rows={3}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            },
                            mb: 2
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editingPromotion?.activo || false}
                                onChange={(e) => setEditingPromotion({
                                    ...editingPromotion,
                                    activo: e.target.checked
                                })}
                                color="primary"
                            />
                        }
                        label="Activo"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenDialog(false)}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleEditSave}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
} 