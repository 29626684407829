import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, 
    Paper, 
    TextField, 
    IconButton, 
    Typography
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const botResponses = {
    greeting: [
        "¡Hola! ¿En qué puedo ayudarte?",
        "¡Bienvenida a EP Boutique! ¿Qué necesitas?"
    ],
    products: [
        "Tenemos una gran variedad de productos en nuestro catálogo. <b>Haz clic aquí</b> para ver toda nuestra colección de joyas y accesorios.",
        "¿Te gustaría ver nuestro catálogo completo? <b>Haz clic aquí</b> para descubrir todas nuestras piezas exclusivas."
    ],
    shipping: [
        "Realizamos envíos a toda España. El tiempo estimado de entrega es de 24-48 horas laborables",
        "Los gastos de envío son gratuitos para pedidos superiores a 50€"
    ],
    payment: [
        "Aceptamos tarjetas de crédito/débito y PayPal",
        "Todos los pagos son procesados de forma segura"
    ],
    returns: [
        "Tienes 14 días para devolver tu pedido",
        "Las devoluciones son gratuitas"
    ],
    howToBuy: [
        "¿Te gustaría ver nuestra guía de compra? <b>Haz clic aquí</b> y te mostraré cómo comprar paso a paso",
        "Parece que necesitas ayuda con el proceso de compra. ¿Quieres ver nuestra guía detallada?"
    ],
    location: [
        "Estamos ubicados en Madrid, España. Realizamos envíos personalizados a toda España. <b>Haz clic aquí</b> para ver más detalles sobre envíos.",
        "Nuestra boutique está en Madrid, y hacemos envíos personalizados según tus necesidades. ¿Te gustaría más información?"
    ],
    default: [
        "Parece que necesitas ayuda con el proceso de compra. ¿Te gustaría ver nuestra guía paso a paso? <b>Haz clic aquí</b>",
        "Para ayudarte mejor, puedo mostrarte nuestra guía de compra detallada. ¿Te gustaría verla?"
    ]
};

const ChatBot = ({ onClose }) => {
    const [messages, setMessages] = useState([
        { text: "¡Hola! Soy el asistente virtual de EP Boutique. ¿En qué puedo ayudarte?", isBot: true }
    ]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSend = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            // Agregar mensaje del usuario
            setMessages(prev => [...prev, { text: newMessage, isBot: false }]);
            setNewMessage('');

            // Simular respuesta del bot después de un pequeño delay
            setTimeout(() => {
                const botResponse = getBotResponse(newMessage);
                setMessages(prev => [...prev, { text: botResponse, isBot: true }]);
            }, 1000);
        }
    };

    const handleMessageClick = (message) => {
        if (message.text.includes("Haz clic aquí")) {
            if (message.text.includes("catálogo") || message.text.includes("colección")) {
                window.location.href = '/products';
            } else {
                window.location.href = '/how-to-buy';
            }
            if (onClose) onClose();
        }
    };

    const getBotResponse = (message) => {
        const msg = message.toLowerCase();
        if (msg.includes('hola') || msg.includes('buenos días') || msg.includes('buenas')) {
            return botResponses.greeting[Math.floor(Math.random() * botResponses.greeting.length)];
        }
        if (msg.includes('producto') || msg.includes('catálogo') || 
            msg.includes('vende') || msg.includes('tiene') || 
            msg.includes('joya') || msg.includes('accesorio') ||
            msg.includes('colección')) {
            return botResponses.products[Math.floor(Math.random() * botResponses.products.length)];
        }
        if (msg.includes('donde') || msg.includes('ubicación') || msg.includes('dirección') || 
            msg.includes('madrid') || msg.includes('españa') || msg.includes('tienda')) {
            return botResponses.location[Math.floor(Math.random() * botResponses.location.length)];
        }
        if (msg.includes('envío') || msg.includes('enviar') || msg.includes('entrega') || 
            msg.includes('personalizado') || msg.includes('especial')) {
            return botResponses.shipping[Math.floor(Math.random() * botResponses.shipping.length)];
        }
        if (msg.includes('pago') || msg.includes('pagar') || msg.includes('tarjeta')) {
            return botResponses.payment[Math.floor(Math.random() * botResponses.payment.length)];
        }
        if (msg.includes('devolver') || msg.includes('devolución') || msg.includes('cambio')) {
            return botResponses.returns[Math.floor(Math.random() * botResponses.returns.length)];
        }
        if (msg.includes('comprar') || msg.includes('cómo') || msg.includes('como')) {
            return botResponses.howToBuy[Math.floor(Math.random() * botResponses.howToBuy.length)];
        }
        return botResponses.default[Math.floor(Math.random() * botResponses.default.length)];
    };

    return (
        <Paper sx={{ 
            width: { xs: '90%', sm: 350 },
            height: 500,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden'
        }}>
            <Box sx={{ 
                bgcolor: '#d7c0ae',
                p: 2,
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6">Chat EP Boutique</Typography>
            </Box>

            <Box sx={{ 
                flex: 1,
                overflowY: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                bgcolor: '#f8f4f1'
            }}>
                {messages.map((message, index) => (
                    <Box
                        key={index}
                        onClick={() => message.isBot && handleMessageClick(message)}
                        sx={{
                            alignSelf: message.isBot ? 'flex-start' : 'flex-end',
                            bgcolor: message.isBot ? 'white' : '#d7c0ae',
                            color: message.isBot ? '#5a4a42' : 'white',
                            p: 2,
                            borderRadius: 2,
                            maxWidth: '80%',
                            boxShadow: 1,
                            cursor: message.isBot ? 'pointer' : 'default',
                            '&:hover': message.isBot ? {
                                bgcolor: '#f8f4f1',
                                transition: 'background-color 0.3s'
                            } : {}
                        }}
                    >
                        <Typography 
                            variant="body2" 
                            dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                    </Box>
                ))}
                <div ref={messagesEndRef} />
            </Box>

            <Box 
                component="form" 
                onSubmit={handleSend} 
                sx={{ 
                    p: 2, 
                    bgcolor: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.1)'
                }}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Escribe un mensaje..."
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 3
                            }
                        }}
                    />
                    <IconButton 
                        type="submit"
                        sx={{ 
                            bgcolor: '#d7c0ae',
                            color: 'white',
                            '&:hover': { bgcolor: '#c4ae9d' }
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
};

export default ChatBot; 