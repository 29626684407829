import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { createColor, getColors, deleteColor, updateColor } from '../../services/color';
import { HexColorPicker } from 'react-colorful';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#f8f4f1',
    borderRadius: '12px',
    boxShadow: '8px 8px 16px #e1ddd9, -8px -8px 16px #ffffff',
    marginTop: '20px',
    overflow: 'hidden',
});

const StyledTable = styled(Table)({
    minWidth: 650,
    '& .MuiTableCell-root': {
        borderBottom: '1px solid rgba(215,192,174,0.2)',
        padding: '16px',
    },
});

const StyledTableHead = styled(TableHead)({
    '& .MuiTableCell-root': {
        backgroundColor: 'rgba(215,192,174,0.1)',
        color: '#5a4a42',
        fontWeight: 600,
        fontSize: '0.95rem',
        whiteSpace: 'nowrap',
    },
});

const StyledTableRow = styled(TableRow)({
    '&:hover': {
        backgroundColor: 'rgba(215,192,174,0.05)',
    },
    transition: 'background-color 0.3s ease',
});

const ColorPreview = styled(Box)(({ color }) => ({
    width: '50px',
    height: '50px',
    borderRadius: '10px',
    backgroundColor: color,
    border: '3px solid #ffffff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    margin: '0 auto',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const ActionButton = styled(IconButton)({
    padding: '8px',
    margin: '0 4px',
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const ColorPickerContainer = styled(Box)({
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    '& .react-colorful': {
        width: '100% !important',
        maxWidth: '300px',
    },
});

function Colors() {
    const [colors, setColors] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentColor, setCurrentColor] = useState({ id: null, color: '' });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchColors();
    }, []);

    const fetchColors = async () => {
        try {
            const response = await getColors();
            setColors(response);
        } catch (error) {
            console.error('Error fetching colors:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
   
            if(!isEditing){
                const response = await createColor(currentColor);
                console.log("status", response.status);
                if (response.status === "ok") {
                    fetchColors();
                    handleCloseDialog();
                }
            } else {
                const response = await updateColor(currentColor.id, currentColor);
                console.log("status", response.status);
                if (response.status === "ok") {
                    fetchColors();
                    handleCloseDialog();
                }
            }
        } catch (error) {
            console.error('Error saving color:', error);
        }
        
    };

    const handleDelete = async (id) => {    
        console.log(id);
        if (window.confirm('¿Estás seguro de que quieres eliminar este color?')) {
            await deleteColor(id);
            fetchColors();
        }
    };

    const handleOpenDialog = (color = null) => {
        setIsEditing(!!color);
        setCurrentColor(color || { id: null, color: '' });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentColor({ id: null, color: '' });
        setIsEditing(false);
    };

    const handleColorChange = (newColor) => {
        setCurrentColor(prev => ({
            ...prev,
            color: newColor.toUpperCase()
        }));
    };

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography 
                    variant="h4" 
                    sx={{ 
                        color: '#5a4a42',
                        fontWeight: 500,
                    }}
                >
                    Gestión de Colores
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        '&:hover': {
                            backgroundColor: '#c4ae9d',
                        },
                        borderRadius: '8px',
                        padding: '8px 16px',
                    }}
                >
                    Añadir Color
                </Button>
            </Box>

            <StyledTableContainer component={Paper}>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <TableCell align="center">Vista Previa</TableCell>
                            <TableCell>Código Hex</TableCell>
                            <TableCell align="right">Acciones</TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {colors.map((color) => (
                            <StyledTableRow key={color.id}>
                                <TableCell align="center">
                                    <ColorPreview 
                                        color={color.color}
                                        onClick={() => handleOpenDialog(color)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography 
                                        sx={{ 
                                            color: '#5a4a42',
                                            fontFamily: 'monospace',
                                            fontSize: '1rem',
                                            fontWeight: 500
                                        }}
                                    >
                                        {color.color}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <ActionButton 
                                        onClick={() => handleOpenDialog(color)}
                                        sx={{ 
                                            color: '#5a4a42',
                                            backgroundColor: 'rgba(215,192,174,0.1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(215,192,174,0.2)',
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </ActionButton>
                                    <ActionButton 
                                        onClick={() => handleDelete(color.id)}
                                        sx={{ 
                                            color: '#e57373',
                                            backgroundColor: 'rgba(229,115,115,0.1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(229,115,115,0.2)',
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </ActionButton>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {isEditing ? 'Editar Color' : 'Añadir Color'}
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <ColorPickerContainer>
                            <HexColorPicker 
                                color={currentColor.hex} 
                                onChange={handleColorChange}
                            />
                            <TextField
                                margin="dense"
                                label="Código Hexadecimal"
                                type="text"
                                fullWidth
                                value={currentColor.color}
                                onChange={(e) => setCurrentColor({ 
                                    ...currentColor, 
                                    color: e.target.value.toUpperCase() 
                                })}
                                placeholder="#000000"
                                helperText="Ejemplo: #FF0000 para rojo"
                                InputProps={{
                                    startAdornment: (
                                        <Box
                                            sx={{
                                                width: 24,
                                                height: 24,
                                                borderRadius: 1,
                                                mr: 1,
                                                backgroundColor: currentColor.hex || '#fff',
                                                border: '1px solid #e0e0e0'
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </ColorPickerContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleCloseDialog}
                            sx={{ color: '#5a4a42' }}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{ 
                                backgroundColor: '#d7c0ae',
                                '&:hover': {
                                    backgroundColor: '#c4ae9d',
                                }
                            }}
                        >
                            {isEditing ? 'Guardar' : 'Añadir'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}

export default Colors; 