import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Breadcrumbs({ items }) {
    return (
        <Stack 
            direction="row" 
            spacing={1} 
            alignItems="center"
            sx={{ 
                padding: '8px',
                color: '#666',
                fontSize: '0.875rem',
                fontFamily: 'Georgia, serif'
            }}
        >
            <Box 
                component={Link} 
                to="/" 
                sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    color: '#5a4a42',
                    textDecoration: 'none',
                    '&:hover': {
                        color: '#000'
                    }
                }}
            >
                <HomeIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                Inicio
            </Box>

            {items.map((item, index) => (
                <React.Fragment key={item.path}>
                    <NavigateNextIcon fontSize="small" />
                    {item.disabled ? (
                        <Box
                            sx={{ 
                                color: '#b0a199',
                                fontWeight: 'medium'
                            }}
                        >
                            {item.label}
                        </Box>
                    ) : (
                        <Box 
                            component={Link} 
                            to={item.path}
                            sx={{
                                color: '#5a4a42',
                                textDecoration: 'none',
                                '&:hover': {
                                    color: '#000'
                                }
                            }}
                        >
                            {item.label}
                        </Box>
                    )}
                </React.Fragment>
            ))}
        </Stack>
    );
}

export default Breadcrumbs; 