export const getColors = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}colors`);
    const data = await response.json();
    return data;
};

export const createColor = async (color) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}color`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(color),
    });
    return response.json();
};

export const deleteColor = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}color/${id}`, {
        method: 'DELETE',
    });
    return response.json();
};

export const updateColor = async (id, color) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}color/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(color),
    });
    return response.json();
};
