import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    IconButton,
    Typography,
    Paper,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { getCategories, createCategory, updateCategory, deleteCategory } from '../../services/category';

export default function CategoriesPanel() {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await getCategories();
            setCategories(data);
        } catch (error) {
            console.error('Error al cargar categorías:', error);
        }
    };

    const filteredCategories = categories.filter(category =>
        category.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAddCategory = async () => {
        if (newCategory.trim()) {
            try {
                await createCategory({ nombre: newCategory.trim() });
                setNewCategory('');
                setOpenAddDialog(false);
                fetchCategories();
            } catch (error) {
                console.error('Error al crear categoría:', error);
            }
        }
    };

    const handleEditClick = (category) => {
        setEditingCategory(category);
        setEditedName(category.nombre);
        setOpenDialog(true);
    };

    const handleEditSave = async () => {
        if (editedName.trim() && editingCategory) {
            try {
                await updateCategory(editingCategory.id, { nombre: editedName.trim() });
                setOpenDialog(false);
                fetchCategories();
            } catch (error) {
                console.error('Error al actualizar categoría:', error);
            }
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta categoría?')) {
            try {
                await deleteCategory(id);
                fetchCategories();
            } catch (error) {
                console.error('Error al eliminar categoría:', error);
            }
        }
    };

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
            }}>
                <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                    Gestión de Categorías
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenAddDialog(true)}
                    sx={{
                        backgroundColor: '#d7c0ae',
                        '&:hover': {
                            backgroundColor: '#c4ae9d'
                        }
                    }}
                >
                    Nueva Categoría
                </Button>
            </Box>

            {/* Buscador */}
            <Box sx={{ mb: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar categoría..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#5a4a42' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: '#f8f4f1',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#d7c0ae',
                            },
                            '&:hover fieldset': {
                                borderColor: '#c4ae9d',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#5a4a42',
                            },
                        },
                    }}
                />
            </Box>

            <Grid container spacing={2}>
                {filteredCategories.map((category) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={category.id}>
                        <Paper
                            sx={{
                                p: 2,
                                height: '100px', // Altura reducida
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                backgroundColor: '#f8f4f1',
                                borderRadius: 2,
                                boxShadow: '4px 4px 8px #e1ddd9, -4px -4px 8px #ffffff',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-3px)'
                                }
                            }}
                        >
                            <Typography 
                                variant="subtitle1" 
                                sx={{ 
                                    color: '#5a4a42',
                                    textAlign: 'center',
                                    wordBreak: 'break-word',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    mb: 1
                                }}
                            >
                                {category.nombre}
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center',
                                gap: 1
                            }}>
                                <IconButton 
                                    size="small"
                                    onClick={() => handleEditClick(category)}
                                    sx={{ 
                                        color: '#5a4a42',
                                        backgroundColor: 'rgba(215,192,174,0.2)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(215,192,174,0.4)'
                                        }
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton 
                                    size="small"
                                    onClick={() => handleDelete(category.id)}
                                    sx={{ 
                                        color: '#e57373',
                                        backgroundColor: 'rgba(229,115,115,0.1)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(229,115,115,0.2)'
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Diálogo para añadir nueva categoría */}
            <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
                <DialogTitle sx={{ color: '#5a4a42' }}>Nueva Categoría</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Nombre de la categoría"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenAddDialog(false)}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleAddCategory}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para editar categoría */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle sx={{ color: '#5a4a42' }}>Editar Categoría</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#ffffff'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenDialog(false)}
                        sx={{ color: '#5a4a42' }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleEditSave}
                        sx={{
                            backgroundColor: '#d7c0ae',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#c4ae9d'
                            }
                        }}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
} 