export const getProducts = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products`);
    const data = await response.json();
    return data;
};

export const getAllProducts = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}all/products`);
    const data = await response.json();
    return data;
};

export const createProduct = async (product) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(product)
    });
    return response.json();
}

export const deleteProduct = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}`, {
        method: 'DELETE'
    });
    return response.json();
}

export const updateProduct = async (id, product) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(product),
    });
    return response.json();
};

export const uploadProductImages = async (id, images) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}/images`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ images })
    });
    return response.json();
}

export const deleteProductImage = async (id, imageId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}/images/${imageId}`, {
        method: 'DELETE'
    });
    return response.json();
}

export const updateProductImageMain = async (id, imageId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}/images/${imageId}/main`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            is_main: 1,
            product_id: id  // Enviamos el id del producto para actualizar todas las imágenes
        })
    });
    
    if (!response.ok) {
        throw new Error('Error al actualizar la imagen principal');
    }
    
    return response.json();
}

export const getProductById = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}`);
    return response.json();
}

