import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Paper,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ColorsAdminPanel from './Colors';
import CategoriesPanel from './CategoriesPanel';
import SizesPanel from './SizesPanel';
import ProductsPanel from './ProductsPanel';
import PromotionsPanel from './PromotionsPanel';
import LoginAdmin from './LoginAdmin';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardPanel from './DashboardPanel';
import SettingsPanel from './SettingsPanel';
import OrdersPanel from './OrdersPanel';

const drawerWidth = 240;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#f8f4f1',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: theme.zIndex.drawer + 1,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        backgroundColor: '#f8f4f1',
        border: 'none',
        boxShadow: '2px 0 8px rgba(0,0,0,0.05)',
    },
}));

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '64px',
});

function AdminLayout() {
    const [open, setOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState('home');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [lastActivity, setLastActivity] = useState(Date.now());
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
    }, [isMobile]);

    useEffect(() => {
        // Verificar si existe un token de autenticación
        const adminToken = localStorage.getItem('adminToken');
        if (adminToken) {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        setIsAuthenticated(false);
    };

    useEffect(() => {
        const activityCheck = () => {
            setLastActivity(Date.now());
        };

        // Eventos para detectar actividad del usuario
        window.addEventListener('mousemove', activityCheck);
        window.addEventListener('keydown', activityCheck);
        window.addEventListener('click', activityCheck);

        // Timer para verificar inactividad
        const inactivityTimer = setInterval(() => {
            if (Date.now() - lastActivity > 5 * 60 * 1000) { // 5 minutos
                handleLogout();
            }
        }, 30000); // Verificar cada 30 segundos

        return () => {
            window.removeEventListener('mousemove', activityCheck);
            window.removeEventListener('keydown', activityCheck);
            window.removeEventListener('click', activityCheck);
            clearInterval(inactivityTimer);
        };
    }, [lastActivity]);

    if (!isAuthenticated) {
        return <LoginAdmin onLoginSuccess={() => setIsAuthenticated(true)} />;
    }

    const renderContent = () => {
        switch (currentPage) {
            case 'home':
                return <DashboardPanel />;
            case 'orders':
                return <OrdersPanel />;
            case 'products':
                return <ProductsPanel />;
            case 'categories':
                return <CategoriesPanel/>;
            case 'colors':
                return <ColorsAdminPanel />;
            case 'sizes':
                return <SizesPanel />;
            case 'promotions':
                return <PromotionsPanel />;
            case 'settings':
                return <SettingsPanel />;
            default:
                return (
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h4" sx={{ color: '#5a4a42', mb: 2 }}>
                            Bienvenido al Panel Administrativo
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#b0a199' }}>
                            Selecciona una opción del menú para comenzar.
                        </Typography>
                    </Box>
                );
        }
    };

    const menuItems = [
        { id: 'home', text: 'Panel Principal', icon: '🏠' },
        { id: 'orders', text: 'Pedidos', icon: '📦' },
        { id: 'products', text: 'Productos', icon: '👕' },
        { id: 'categories', text: 'Categorías', icon: '📁' },
        { id: 'colors', text: 'Colores', icon: '🎨' },
        { id: 'sizes', text: 'Tallas', icon: '👖' },
        { id: 'promotions', text: 'Promociones', icon: '🏷️' },
        { id: 'settings', text: 'Configuración', icon: '⚙️' },
    ];

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#fafafa' }}>
            <StyledAppBar 
                position="fixed" 
                sx={{ 
                    width: { xs: '100%', sm: open ? `calc(100% - ${drawerWidth}px)` : '100%' },
                    ml: { xs: 0, sm: open ? `${drawerWidth}px` : 0 },
                }}
            >
                <StyledToolbar>
                    <IconButton
                        color="inherit"
                        aria-label="toggle drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{ 
                            mr: 2, 
                            color: '#5a4a42',
                        }}
                    >
                        {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                    <Typography variant="h6" sx={{ 
                        color: '#5a4a42', 
                        flexGrow: 1,
                        fontSize: { xs: '1rem', sm: '1.25rem' }
                    }}>
                        {menuItems.find(item => item.id === currentPage)?.text || 'Panel de Administración'}
                    </Typography>
                    
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: { xs: 1, sm: 2 }
                    }}>
                        <Typography variant="body2" sx={{ 
                            color: '#5a4a42',
                            display: { xs: 'none', sm: 'block' }
                        }}>
                            {process.env.REACT_APP_ADMIN_USERNAME}
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<LogoutIcon />}
                            onClick={handleLogout}
                            sx={{
                                color: '#5a4a42',
                                borderColor: '#5a4a42',
                                '&:hover': {
                                    borderColor: '#4a3a32',
                                    backgroundColor: 'rgba(90, 74, 66, 0.04)'
                                },
                                px: { xs: 1, sm: 2 }
                            }}
                        >
                            {isMobile ? '' : 'Cerrar Sesión'}
                        </Button>
                    </Box>
                </StyledToolbar>
            </StyledAppBar>

            <StyledDrawer
                variant={isMobile ? "temporary" : "persistent"}
                anchor="left"
                open={open}
                onClose={() => isMobile && setOpen(false)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#f8f4f1',
                        border: 'none',
                        boxShadow: '2px 0 8px rgba(0,0,0,0.05)',
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', mt: 2 }}>
                    {menuItems.map((item) => (
                        <Box
                            key={item.id}
                            onClick={() => {
                                setCurrentPage(item.id);
                                isMobile && setOpen(false);
                            }}
                            sx={{
                                p: 2,
                                mx: 2,
                                mb: 1,
                                cursor: 'pointer',
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: currentPage === item.id ? 'rgba(215,192,174,0.2)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: 'rgba(215,192,174,0.1)',
                                },
                            }}
                        >
                            <span style={{ marginRight: '12px', fontSize: '20px' }}>{item.icon}</span>
                            <Typography sx={{ color: '#5a4a42' }}>
                                {item.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </StyledDrawer>

            <Box component="main" sx={{ 
                flexGrow: 1, 
                p: { xs: 2, sm: 3 },
                marginLeft: { xs: 0, sm: open ? `${drawerWidth}px` : 0 },
                marginTop: '64px',
                transition: theme => theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}>
                <Paper sx={{
                    p: { xs: 2, sm: 3 },
                    borderRadius: 2,
                    boxShadow: '8px 8px 16px #e1ddd9, -8px -8px 16px #ffffff'
                }}>
                    {renderContent()}
                </Paper>
            </Box>
        </Box>
    );
}

export default AdminLayout; 