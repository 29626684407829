import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Slide } from '@mui/material';

function CookiesBanner() {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Comprobar si el usuario ya aceptó las cookies
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <Slide direction="up" in={showBanner}>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'rgba(255, 255, 255, 0.95)',
                    boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
                    p: 2,
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2
                }}
            >
                <Typography variant="body2" color="#5a4a42">
                    Utilizamos cookies para mejorar tu experiencia en nuestra web. 
                    Al continuar navegando, aceptas nuestra 
                    <Button 
                        color="primary"
                        size="small"
                        onClick={() => window.open('/privacy-policy', '_blank')}
                        sx={{ 
                            textTransform: 'none',
                            p: '0 4px',
                            minWidth: 'auto',
                            color: '#d7c0ae',
                            '&:hover': {
                                bgcolor: 'transparent',
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        política de cookies
                    </Button>.
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleAccept}
                    size="small"
                    sx={{
                        bgcolor: '#d7c0ae',
                        color: '#5a4a42',
                        '&:hover': {
                            bgcolor: '#b0a199'
                        },
                        textTransform: 'none',
                        px: 3
                    }}
                >
                    Aceptar
                </Button>
            </Box>
        </Slide>
    );
}

export default CookiesBanner; 