import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, Box, 
    Chip, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { useSettings } from '../context/SettingsContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import 'swiper/css/bundle';
import Breadcrumbs from './Breadcrumbs';
import RelatedProducts from './RelatedProducts';

const ColorChip = styled(Chip)(({ color }) => ({
    backgroundColor: color,
    margin: '0 4px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
}));

const SizeChip = styled(Chip)(({ theme }) => ({
    margin: '4px',
    backgroundColor: '#f8f4f1',
    color: '#5a4a42',
    boxShadow: 'inset 2px 2px 5px #e1ddd9, inset -2px -2px 5px #ffffff',
}));

const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '400px',
    borderRadius: '15px',
    background: 'linear-gradient(145deg, #f0ece9, #fff)',
    boxShadow: `
        inset 3px 3px 6px #d4d0cc,
        inset -3px -3px 6px #fcf8f5
    `,
});

const MainImageContainer = styled(Box)({
    width: '100%',
    height: '400px',
    marginBottom: '16px',
    borderRadius: '15px',
    overflow: 'hidden',
    position: 'relative',
    '& .swiper': {
        width: '100%',
        height: '100%',
    },
    '& .swiper-slide': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    '& .swiper-button-next, & .swiper-button-prev': {
        color: '#5a4a42',
        backgroundColor: 'rgba(255,255,255,0.7)',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        '&::after': {
            fontSize: '16px'
        },
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    '& .swiper-pagination': {
        bottom: '8px !important',
    },
    '& .swiper-pagination-bullet': {
        backgroundColor: '#5a4a42',
        margin: '0 4px !important',
    },
    '& .swiper-pagination-bullet-active': {
        backgroundColor: '#d7c0ae',
    },
    '& .swiper-zoom-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent'
    }
});

function ProductDetail({ product, categoryName, relatedProducts }) {
    const { settings } = useSettings();
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [loadedImages, setLoadedImages] = useState(0);

    useEffect(() => {
        if (product) {
            setImagesLoaded(false);
            setLoadedImages(0);

            const imagePromises = product.imagenes.map(imagen => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => {
                        setLoadedImages(prev => prev + 1);
                        resolve();
                    };
                    img.onerror = reject;
                    img.src = imagen.url;
                });
            });

            Promise.all(imagePromises)
                .then(() => {
                    setImagesLoaded(true);
                })
                .catch(error => {
                    console.error('Error cargando imágenes:', error);
                    setImagesLoaded(true);
                });
        }
    }, [product]);

    if (!product) return null;

    const breadcrumbItems = [
        {
            path: '/products',
            label: 'Productos'
        },
        {
            path: `/product/${product.id}`,
            label: product.nombre,
            disabled: true
        }
    ];

    return (
        <Box sx={{ minHeight: 'calc(100vh - 300px)' }}>
            <Box sx={{ mb: 3 }}>
                <Breadcrumbs items={breadcrumbItems} />
            </Box>

            <Box sx={{ 
                backgroundColor: '#f8f4f1',
                borderRadius: '20px',
                padding: '24px',
                boxShadow: `
                    8px 8px 20px #d4d0cc,
                    -8px -8px 20px #fcf8f5
                `,
                mb: 4
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        {!imagesLoaded ? (
                            <LoadingContainer>
                                <Box sx={{ textAlign: 'center' }}>
                                    <CircularProgress sx={{ color: '#d7c0ae', mb: 2 }} />
                                    <Typography variant="body2" sx={{ color: '#5a4a42' }}>
                                        Cargando imágenes ({loadedImages}/{product.imagenes.length})
                                    </Typography>
                                </Box>
                            </LoadingContainer>
                        ) : (
                            <MainImageContainer>
                                <Swiper
                                    modules={[Navigation, Pagination, Zoom]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    zoom={{
                                        maxRatio: 3,
                                        minRatio: 1
                                    }}
                                    loop={true}
                                >
                                    {product.imagenes.map((imagen, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="swiper-zoom-container">
                                                <img
                                                    src={imagen.url}
                                                    alt={`${product.nombre} - imagen ${index + 1}`}
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        width: 'auto',
                                                        height: 'auto',
                                                        objectFit: 'contain',
                                                        display: 'block',
                                                        margin: 'auto'
                                                    }}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </MainImageContainer>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#5a4a42' }}>
                                {product.nombre}
                            </Typography>
                            
                            <Typography variant="subtitle1" sx={{ color: '#b0a199', mb: 2 }}>
                                {categoryName}
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                                <Typography variant="h5" sx={{ color: '#e57373', fontWeight: 'bold', mr: 2 }}>
                                    {settings.find(setting => setting.key_name === 'moneda')?.value} {product.precio}
                                </Typography>
                            </Box>

                            {product.descripcion && (
                                <Typography variant="body1" sx={{ color: '#5a4a42', mb: 3 }}>
                                    {product.descripcion}
                                </Typography>
                            )}

                            {product.enable_colors && product.colores && product.colores.length > 0 && (
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle1" sx={{ color: '#5a4a42', mb: 1 }}>
                                        Colores disponibles:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {product.colores.map((color) => (
                                            <ColorChip key={color} color={color} />
                                        ))}
                                    </Box>
                                </Box>
                            )}

                            {product.enable_sizes && product.tallas && product.tallas.length > 0 && (
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle1" sx={{ color: '#5a4a42', mb: 1 }}>
                                        Tallas disponibles:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {product.tallas.map((talla) => (
                                            <SizeChip key={talla} label={talla} />
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <RelatedProducts products={relatedProducts} />
        </Box>
    );
}

export default ProductDetail; 