import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Paper,
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSettings } from '../context/SettingsContext';

export default function WishlistSubmitDialog({ open, onClose, wishlist, onSubmit }) {
    const [formData, setFormData] = useState({
        nombre: '',
        direccion: '',
        telefono: '',
        email: ''
    });
    
    const [searchValue, setSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const { settings } = useSettings();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const searchAddress = async (query) => {
        if (query.length < 3) {
            setSuggestions([]);
            return;
        }

        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
            );
            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            console.error('Error buscando direcciones:', error);
        }
    };

    const handleSelectAddress = (address) => {
        setSearchValue(address.display_name);
        setShowSuggestions(false);
        setFormData(prev => ({
            ...prev,
            direccion: address.display_name
        }));
    };

    const handleSubmit = async () => {
        if (!formData.nombre || !searchValue || !formData.telefono || !formData.email) {
            alert('Por favor, completa todos los campos');
            return;
        }

        const orderData = {
            ...formData,
            direccion: searchValue,
            estado: 'pendiente',
            productos: JSON.stringify(wishlist.map(item => ({
                id: item.id,
                nombre: item.nombre,
                precio: item.precio,
                precio_oferta: item.precio_oferta,
                cantidad: item.quantity || 1,
                imagen: item.imagenes?.[0]?.url || ''
            })))
        };

        try {
            await onSubmit(orderData);
            onClose();
        } catch (error) {
            console.error('Error al enviar el pedido:', error);
            alert('Error al enviar el pedido. Por favor, intenta de nuevo.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Completa tus datos</DialogTitle>
            <DialogContent>
                <Accordion sx={{ mb: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ bgcolor: 'rgba(90, 74, 66, 0.08)' }}
                    >
                        <Typography>
                            Productos seleccionados ({wishlist.length})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List sx={{ width: '100%', maxHeight: 200, overflow: 'auto' }}>
                            {wishlist.map((item, index) => (
                                <ListItem key={index} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={item.nombre}
                                            src={item.imagenes?.[0]?.url}
                                            variant="rounded"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.nombre}
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {settings.find(setting => setting.key_name === 'moneda')?.value} {item.precio}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ ml: 1 }}
                                                >
                                                    {` · Cantidad: ${item.quantity || 1}`}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>

                <TextField
                    fullWidth
                    label="Nombre completo"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                    margin="normal"
                    required
                />

                <Box sx={{ position: 'relative', mb: 2 }}>
                    <TextField
                        fullWidth
                        label="¿Dónde?"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            setShowSuggestions(true);
                            searchAddress(e.target.value);
                        }}
                        margin="normal"
                        required
                        placeholder="Buscar dirección..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {showSuggestions && suggestions.length > 0 && (
                        <Paper
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                maxHeight: '300px',
                                overflow: 'auto',
                                zIndex: 1000,
                                mt: 1,
                                borderRadius: 1,
                                boxShadow: 3
                            }}
                        >
                            {suggestions.map((suggestion, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 2,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'rgba(90, 74, 66, 0.1)'
                                        },
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                    }}
                                    onClick={() => handleSelectAddress(suggestion)}
                                >
                                    <Typography variant="body2">
                                        {suggestion.display_name}
                                    </Typography>
                                </Box>
                            ))}
                        </Paper>
                    )}
                </Box>

                <TextField
                    fullWidth
                    label="Teléfono"
                    name="telefono"
                    value={formData.telefono}
                    onChange={handleChange}
                    margin="normal"
                    required
                />

                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    margin="normal"
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
} 